import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../../Styles/Dashboard.css';
import UserType from '../../Helpers/getUserType';
import ButtonLoader from '../../Helpers/buttonLoader';
import { Plus, PlusIcon, Search } from 'lucide-react';
import filterIcon from '../../assets/images/filter.svg'
import { Close } from '@mui/icons-material';
import FilterDropdown from './FilterDropdown';
import { numericValue } from '../../services/common.utils';
import TypeDropdown from './TypeDropdown';
import UserTable from './UserTable';
import WithdrawFunds from '../../Components/Modal/WithdrawFunds';
import { toast } from 'react-toastify';
import AddAsset, { defaultAssetData } from '../../Components/Modal/AddAsset';

const UsersList = ({ updateAnalyticsStat, fetchAnalyticsData }) => {
	let token = Cookies.get("token");
	const [openUpdate, setOpenUpdate] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [openAsset, setOpenAsset] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [balanceUpdate, setBalanceUpdate] = useState(false);
	const [currentUser, setCurrentUser] = useState({});
	const [currentIndex, setCurrentIndex] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectType, setSelectType] = useState('Profit');
	const [buttonLoader, setButtonLoader] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [recordsFiltered, setRecordsFiltered] = useState(0);
	const [showArchieved, setShowArchieved] = useState(false)
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [balance, setBalance] = useState(0);
	const [addFunds, setAddFunds] = useState('');
	const [archivedUsers, setArchivedUsers] = useState([])
	const [currentPage, setCurrentPage] = useState(1);
	const [withdrawFunds, setWithdrawFunds] = useState(0)
	const [openWithdraw, setOpenWithdraw] = useState(false)
	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();
	const [addAsset, setAddAsset] = useState(defaultAssetData);
	const recordsPerPage = 10;
	const [sortingOption, setSortingOption] = useState('latest');

	// Sorting logic based on the selected option
	const sortedData = React.useMemo(() => {
		let sortedArray = showArchieved === true ? [...archivedUsers] : [...data];
		// switch (sortingOption) {
		// 	case 'latest':
		// 		sortedArray.sort((a, b) => new Date(b.date) - new Date(a.date));
		// 		break;
		// 	case 'profit':
		// 		sortedArray.sort((a, b) => numericValue(b.profitLossAmount) - numericValue(a.profitLossAmount));
		// 		break;
		// 	case 'balance':
		// 		sortedArray.sort((a, b) => numericValue(b.balance) - numericValue(a.balance));
		// 		break;
		// 	case 'Deleted':
		// 		sortedArray = sortedArray.filter(item => item.deleted);
		// 		break;
		// 	default:
		// 		break;
		// }
		return sortedArray;
	}, [showArchieved, archivedUsers, data]);

	useEffect(() => {
		if (sortingOption) {
			if (showArchieved) {
				getArchivedUsers()
			} else {
				getUsers()
			}
		}
	}, [showArchieved, sortingOption])

	// Monitor screen width
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// get archived users
	const getArchivedUsers = async (page = 1) => {
		setLoading(true)
		try {
			let headers = {
				Authorization: `Bearer ${token}`,
			};
			const response = await axios.get(process.env.REACT_APP_BACKEND + `users?status=Inactive&searchValue=${searchValue}&sortby=${sortingOption}`, {
				headers: headers,
			});
			setRecordsFiltered(response.data.recordsFiltered);
			setArchivedUsers(response.data.users);
		} catch (err) {
			// console.error("Error fetching inactive users:", err);
			setArchivedUsers([])
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getArchivedUsers()
	}, [])

	const getUsers = (page = 1) => {
		setLoading(true);
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		}
		axios.get(`${process.env.REACT_APP_BACKEND}users?start=${(currentPage - 1) * recordsPerPage}&searchValue=${searchValue}&sortby=${sortingOption}`, { headers: headers })
			.then((apiResponse) => {
				setData(apiResponse.data.users);
				setRecordsFiltered(apiResponse.data.recordsFiltered);
				setLoading(false);
				updateAnalyticsStat(apiResponse.data)
			})
			.catch((apiError) => {
				setLoading(false);
				if (apiError.response.status === 401) {
					navigate('/login')
				}
			})
	}

	useEffect(() => {
		let token = Cookies.get('token');
		if (token) {
			let userType = UserType(token);
			if (userType === 'USER') navigate('/home');
			else if (userType !== 'ADMIN') navigate('/login')
		}
	}, [])

	const onDeleteAction = () => {
		// Create a shallow copy of the data array
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		};
		let apiRequest = {
			data: {
				userId: data[currentIndex]?.id
			},
			headers: headers
		};
		console.log(headers, apiRequest);
		axios.delete(`${process.env.REACT_APP_BACKEND}users`, apiRequest)
			.then((apiResponse) => {
				getUsers(currentPage, searchValue);
				setOpenDelete(false);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	};

	const ViewDetails = (item) => {
		navigate(`/admin/users/${item.id}`);
		// setLoading(true)
		// setFirstName('');
		// setLastName('')
		// setEmail('')
		// setMobileNumber('')
		// setBalance('')
		// setOpenView(true);
		// console.log('item', item)
		// let token = Cookies.get('token');
		// let headers = {
		// 	Authorization: `Bearer ${token}`
		// }
		// axios.get(`${process.env.REACT_APP_BACKEND}getUserProfile?id=${item.id}`, {headers:headers})
		// .then((apiResponse)=>{
		//     console.log('apiResponse$$$$', apiResponse.data);
		// 	setFirstName(apiResponse.data?.username);
		// 	setLastName(apiResponse.data?.username)
		// 	setEmail(apiResponse.data?.email)
		// 	setMobileNumber(apiResponse.data?.phoneNumber)
		// 	setBalance(apiResponse.data?.balance)
		// 	setLoading(false);
		// 	// setOpenView(true);
		// })
		// .catch((apiError)=>{
		// 	setLoading(false);
		//     console.log('apiError', apiError.message);
		//     // console.log('apiError', apiError.response);
		//     // console.log('apiError', apiError.response.status);
		//     // console.log('apiError', apiError);
		// 	if(apiError.response.status === 401){
		// 		navigate('/login')
		// 	}
		// })
	}

	const handleOpenTradeUpdate = (item, index) => {
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`,
			userId: data[index]?.id,
		};

		let newData = new FormData();
		newData.append("request", JSON.stringify({
			enableTrade: !item.enableTrade
		}));
		axios.post(`${process.env.REACT_APP_BACKEND}updateUserProfile`, newData, { headers: headers })
			.then((apiResponse) => {
				let tempdata = [...data];
				item.enableTrade = !item.enableTrade;
				tempdata[index] = item;
				setData(tempdata);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	}

	const onUpdateBalance = (item, index) => {
		setOpenUpdate(true);
		setCurrentUser(item);
	}

	const onAddFunds = (item, index) => {
		setOpenAdd(true);
		setOpenAsset(false)
		setOpenWithdraw(false)
		setCurrentUser(item);
	}
	const onAddAssets = (item, index) => {
		console.log(item)
		setOpenAsset(true);
		setOpenAdd(false)
		setOpenWithdraw(false)
		setCurrentUser(item);
	}

	const onWithdrawFund = (item, index) => {
		setOpenWithdraw(true);
		setCurrentUser(item);
	}


	const onUpdateAction = (item, index) => {
		setCurrentUser(item);
		setSelectType(item.profitLossType)
		setCurrentIndex(index);
		setOpenUpdate(true);
		console.log('$$$$$$$$$$$$$$', currentUser);
	}

	const onCloseUpdate = () => {
		setOpenUpdate(false)
	}

	const onCloseAdd = () => {
		setOpenAdd(false);
	}
	const onCloseWithdraw = () => {
		setOpenWithdraw(false);
	}
	const onCloseView = () => {
		setOpenView(false)
	}

	const onCloseDelete = () => {
		setOpenDelete(false)
	}

	const handleDropdownChange = (value) => {
		// setSelectType(event.target.value);
		setSelectType(value)
	};

	const save = () => {
		setButtonLoader(true)
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		}
		// console.log(currentUser);
		let apiRequest = {
			"userId": currentUser.id,
			"subscriptionPeriod": currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0,
			"openTrades": currentUser.openTrades ? currentUser.openTrades : 0,
		}
		if (openUpdate) {
			apiRequest.type = selectType;
			apiRequest.amount = Number(currentUser?.profitLossAmount) > 0 ? currentUser?.profitLossAmount?.replace(/[^0-9.]+/g, "") : 0;
		}
		axios.post(`${process.env.REACT_APP_BACKEND}updateBalance`, apiRequest, { headers: headers })
			.then((apiResponse) => {
				let temp = [...data];
				currentUser.profitLossType = selectType;
				if (selectType === 'Profit') {
					currentUser.balance = Number(currentUser.balance) + Number(currentUser.profitLossAmount);
				} else if (selectType === 'Loss') {
					currentUser.balance = Number(currentUser.balance) - Number(currentUser.profitLossAmount);
				}
				temp[currentIndex] = currentUser;
				getUsers();
				setSelectType('')
				setButtonLoader(false);
				setOpenUpdate(false);
				fetchAnalyticsData()
				toast.success(apiResponse.data.message, { theme: 'dark' })
			})
			.catch((apiError) => {
				setLoading(false);
				setOpenUpdate(false);
				console.log('apiError', apiError.messsage);
				setButtonLoader(false);
				if (apiError.response.status == 401) {
					navigate('/login')
				}
			})
	}

	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			...(isMobile ? { minWidth: '250px' } : { minWidth: '300px' }),
			padding: '0px',
			borderRadius: '8px',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
			backgroundColor: '#212F4C',
			border: '0px #28274D solid',
			minWidth: '400px'
		},
	};

	const AddUser = () => {
		navigate('/adduser')
	}

	const DeletePopUp = () => {
		return (
			<Modal
				isOpen={openDelete}
				onRequestClose={onCloseDelete}
				contentLabel="Delete Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}>
					<div>
						<span className='fieldName'>Delete User</span>
					</div>
					<span className='absolute right-2 text-white' onClick={() => setOpenDelete(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '24px' }}>
					<span className='fieldName'>Are you sure you want to delete user?</span>
				</div>
				<div className='flexRow' style={{ marginTop: 15, justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={onDeleteAction}
								className='deleteButton'
							>
								Delete
							</button>
					}
				</div>
			</Modal>
		)
	}

	const AddPopUp = () => {
		return (
			<Modal
				isOpen={openAdd}
				onRequestClose={onCloseAdd}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}>
					<span className='username'>Add Fund</span>
					<span className='absolute right-2 text-white' onClick={() => setOpenAdd(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
				<div className='input-container mt-8 mb-8 p-6'>
					<label className="block text-sm text-[#FFF] mt-1">Add Funds</label>
					<div className="flex relative">
						<select className="adduserInput dropdown text-right" disabled>
							<option>$</option>
						</select>
						<input type="tel" value={addFunds} placeholder="5000" className="adduserInput phone"
							onChange={(e) => {
								setAddFunds(e.target.value);
							}} />
					</div>
				</div>
				<div className='flexRow alignCenter' style={{ padding: '24px' }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={() => onTransactionAction('credit')}
								className='save'
							>
								+ Add Funds
							</button>
					}
				</div>
			</Modal>
		)
	}

	const handleCloseAsset = () => {
		setOpenAsset(false);
		setAddAsset(defaultAssetData)
	};

	// const handleSaveAsset = async () => {
	// 	setLoading(true);
	// 	await new Promise((resolve) => setTimeout(resolve, 2000));
	// 	setLoading(false);
	// 	handleCloseAsset()
	// };

	let headers = {
		Authorization: `Bearer ${token}`
	}

	const handleSaveAsset = async (data) => {
		try {
			// Destructure the necessary properties from `data`
			const { name, amount } = data;
			let headers = {
				Authorization: `Bearer ${token}`
			}
			// Prepare the request body
			const requestBody = {
				coinName: name,
				userId: currentUser?.id,
				amountInvested: amount
			};

			// Make the API call
			const response = await axios.post(process.env.REACT_APP_BACKEND + 'assets', requestBody, {
				headers: headers
			});

			// Handle success response
			if (response.status === 201 || response.status === 200) {
				toast.success('Asset saved successfully!', { theme: 'dark' });
				handleCloseAsset()
			}
		} catch (error) {
			// Handle error
			console.error('Error saving asset:', error);
			toast.error('Failed to save asset', { theme: 'dark' });
		}
	};

	const updateCoinPrice = async (coin) => {
		if (!coin) return; // Early return for invalid input

		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND}coins_price?coin=${coin}`,
				{ headers }
			);

			// Validate response and update state
			if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
				const coinValue = response.data[0]?.coinValue || 0;
				setAddAsset((prev) => ({ ...prev, price: coinValue }));
			} else {
				console.warn('Unexpected response format:', response.data);
				setAddAsset((prev) => ({ ...prev, price: 0 })); // Default to 0 if response is invalid
			}
		} catch (error) {
			console.error('Error getting coin price:', error.message || error);
		}
	};

	const options = [
		{ id: 'profit', label: 'Profit' },
		{ id: 'loss', label: 'Loss' },
	];

	const UpdatePopUp = () => {
		return (
			<Modal
				isOpen={openUpdate}
				onRequestClose={onCloseUpdate}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px', paddingBottom: '16px', borderBottom: '1px solid #28274D' }}>
					<span className='username'>Update Balance</span>
					<span className='absolute right-2 text-white' onClick={() => setOpenUpdate(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', margin: '10px 0' }} />
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Profit/ Loss</span>
					<TypeDropdown options={options} handleDropdownChange={handleDropdownChange} selectedOption={selectType} />
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Amount</span>
					<input
						type="text"
						className='numberInput'
						value={currentUser.profitLossAmount ? currentUser.profitLossAmount.replace(/[^0-9.]+/g, "") : "0"}
						onChange={(e) => {
							let inputValue = e.target.value;

							inputValue = inputValue.replace(/^0+(?=\d)/, '');

							if (currentUser.profitLossAmount && inputValue !== currentUser.profitLossAmount.replace(/[^0-9.]+/g, "")) {
								setBalanceUpdate(true);
							}

							// Allow only numbers, decimal points, and optional leading '-' sign
							if (/^\d*\.?\d*$/.test(inputValue)) {
								let temp = {
									id: currentUser.id,
									userName: currentUser.userName,
									balance: currentUser.balance,
									profitLossAmount: inputValue,
									profitLossType: currentUser.profitLossType,
									openTrades: currentUser.openTrades,
									subscriptionPeriod: currentUser.subscriptionPeriod
								};
								setCurrentUser(temp);
							}
						}}
					></input>
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Cycle days</span>
					<input
						type='number'
						className='numberInput'
						value={currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0}
						onChange={(e) => {
							let inputValue = e.target.value;
							let temp = {
								id: currentUser.id,
								userName: currentUser.userName,
								balance: currentUser.balance,
								profitLossAmount: currentUser.profitLossAmount,
								profitLossType: currentUser.profitLossType,
								openTrades: currentUser.openTrades,
								subscriptionPeriod: inputValue
							};
							setCurrentUser(temp);
						}}
					></input>
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Open Trades</span>
					<input
						type='number'
						className='numberInput'
						value={currentUser.openTrades ? currentUser.openTrades : 0}
						onChange={(e) => {
							let inputValue = e.target.value;
							let temp = {
								id: currentUser.id,
								userName: currentUser.userName,
								balance: currentUser.balance,
								profitLossAmount: currentUser.profitLossAmount,
								profitLossType: currentUser.profitLossType,
								openTrades: inputValue,
								subscriptionPeriod: currentUser.subscriptionPeriod
							};
							console.log(inputValue);
							setCurrentUser(temp);
						}}
					></input>
				</div>
				<div className='flexRow alignCenter' style={{ marginTop: 15, marginBottom: 15 }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter rounded-xl'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={save}
								className='save rounded-xl'
							>
								Save
							</button>
					}
				</div>
			</Modal>
		)
	}


	const renderPersonalData = () => {
		return (
			<>
				{
					loading ? (
						<div className="skeleton profileCard flexCol">
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
						</div>
					) :
						<div className="profileCard flexCol" style={{ width: 'auto' }}>
							<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
								<span className='username'>Yeswanth</span>
								<button
									style={{ backgroundColor: '#E31892', color: '#FFF' }}
									onClick={onCloseView}
								>X</button>
							</div>
							<hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">First Name</span>
								</div>
								{
									firstName ?
										<div>
											<span className="name">{firstName}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Last Name</span>
								</div>
								{
									lastName ?
										<div>
											<span className="name">{lastName}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Mobile Number</span>
								</div>
								{
									mobileNumber ?
										<div>
											<span className="name">{mobileNumber}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Email</span>
								</div>
								{
									email ?
										<div>
											<span className="name">{email}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Balance</span>
								</div>
								{
									balance ?
										<div>
											<span className="name">{balance}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							{/* <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Password</span>
                        </div>
                        {renderField('password', 'Password')}
                    </div> */}
						</div>
				}
			</>
		)
	}

	const customStylesWithoutBackgroundAndPadding = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			...(isMobile ? { minWidth: '250px' } : { minWidth: '500px' }),
			borderRadius: '12px',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
			padding: 0
			// Exclude background color and padding
		},
	};

	const ViewDetailsPopUp = () => {
		return (
			<Modal
				isOpen={openView}
				onRequestClose={onCloseView}
				contentLabel="Example Modal"
				style={customStylesWithoutBackgroundAndPadding}
			>
				{
					renderPersonalData()
				}
			</Modal>
		)
	}

	const handleSearchKeyPress = (event) => {
		if (event.key === 'Enter') {
			console.log(event.key);
			setCurrentPage(1); // Reset to page 1 for new search results
			getUsers(1, searchValue); // Fetch users based on search value
		}
	};

	useEffect(() => {
		getUsers(currentPage);
	}, [currentPage])

	const handleArchivebtn = async () => {
		setShowArchieved(!showArchieved)
	}

	useEffect(() => {
		if (searchValue) {
			setCurrentPage(1);
			if (showArchieved) {
				getArchivedUsers()
			} else {
				getUsers()
			}
		} else {
			if (showArchieved) {
				getArchivedUsers()
			} else {
				getUsers()
			}
		}
	}, [searchValue, showArchieved])


	const onTransactionAction = async (param) => {
		console.log(currentUser)
		let headers = {
			Authorization: `Bearer ${token}`
		}
		if (param) {
			const apiUrl = process.env.REACT_APP_BACKEND + 'transaction';
			const requestBody = {
				userId: currentUser.id,
				transactionType: param,
				fund: param === 'credit' ? addFunds : withdrawFunds
			};

			try {
				const response = await axios.post(apiUrl, requestBody, {
					headers: headers
				});

				if (response.status === 200) {
					toast.success('Transaction completed successfully', { theme: 'dark' });
					getUsers()
					if (param === 'debit') {
						setWithdrawFunds(0)
						fetchAnalyticsData()
						setOpenWithdraw(false)
					} else {
						setAddFunds(0)
						fetchAnalyticsData()
						setOpenAdd(false)
					}
				} else {
					toast.error('Failed to complete the transaction. Please try again.', { theme: 'dark' });
				}
			} catch (error) {
				console.error('Error while making the API call:', error);
				toast.error('An error occurred while processing your request. Please try again.', { theme: 'dark' });
			}
		}
	};



	const onResetAction = async (id) => {
		let headers = {
			Authorization: `Bearer ${token}`
		}
		if (id) {
			const apiUrl = process.env.REACT_APP_BACKEND + 'restoreArchiveUser?userId=' + id;

			try {
				const response = await axios.put(apiUrl, {}, {
					headers: headers
				});

				if (response.status === 200) {
					toast.success('User restored successfully', { theme: 'dark' });
					getArchivedUsers()
				} else {
					toast.error('Failed to reset. Please try again.', { theme: 'dark' });
				}
			} catch (error) {
				console.error('Error while making the API call:', error);
				toast.error('An error occurred while processing your request. Please try again.', { theme: 'dark' });
			}
		}
	}

	// render
	return (
		<div className='w-full bg-[#152445] md:bg-[#212F4C] rounded-2xl'>
			<div className='userTable rounded-2xl'>
				<div className='md:topdivs md:flex md:justify-between items-center'>
					<div className='flexCol' style={{ alignItems: 'flex-start' }}>
						<div className='allusers flex justify-center md:justify-start items-center w-full' >
							<span className='mx-auto md:mx-none'>{showArchieved ? 'Archived Users' : 'All Users'}</span>
						</div>
					</div>
					<div className='flex justify-between md:justify-end gap-2 items-center'>
						<div className='md:hidden' style={{ width: '150px' }}>
							<button
								style={{
									background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
								}}
								onClick={AddUser}
								className='rounded-full sm:w-[150px] py-2 text-white text-center text-sm px-5'>
								<Plus />
							</button>
						</div>
						<div className='relative md:hidden w-[100px] me-3'>
							<Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
							<input
								className='focus:outline-none border-transparent ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
								onKeyDown={handleSearchKeyPress}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Search'
							/>
						</div>
						<div className='relative hidden md:block'>
							<Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
							<input
								className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
								onKeyDown={handleSearchKeyPress}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Search'
							/>
						</div>
						<button
							className={`hidden md:block ${!showArchieved ? 'border-2 border-[#39455F] text-[#D2D4DA]' : 'bg-gradient-to-r from-[#7178FA] via-[#44AFD4] to-[#0AECA9] text-white'} 
							rounded-full py-2 px-4 min-w-[170px] text-center w-fit`} onClick={handleArchivebtn}>
							{!showArchieved ? 'Archived Users' : 'All Users'}
						</button>
						<div className='md:hidden'>
							{/* <img src={filterIcon} alt='filterIcon' className='h-12' /> */}
							<FilterDropdown setSortingOption={setSortingOption} sortingOption={sortingOption} isMobile={isMobile} />
						</div>
						<div className='hidden min-w-[220px] w-fit md:flex items-center justify-center'>
							<FilterDropdown setSortingOption={setSortingOption} sortingOption={sortingOption} isMobile={isMobile} />
						</div>
						<div style={{ width: '95%' }} className='hidden md:block'>
							<button
								style={{
									background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
								}}
								onClick={AddUser}
								className='rounded-full py-2 text-white text-center px-5 md:min-w-[185px]'>
								+ Add User
							</button>
						</div>
					</div>
				</div>

				{/* Users List */}
				<UserTable
					recordsFiltered={showArchieved ? archivedUsers?.length : recordsFiltered}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					searchValue={searchValue}
					getUsers={getUsers}
					showArchieved={showArchieved}
					loading={loading}
					handleOpenTradeUpdate={handleOpenTradeUpdate}
					sortedData={sortedData}
					onUpdateAction={onUpdateAction}
					onUpdateBalance={onUpdateBalance}
					setOpenDelete={setOpenDelete}
					setCurrentIndex={setCurrentIndex}
					setCurrentUser={setCurrentUser}
					ViewDetails={ViewDetails}
					onAddFunds={onAddFunds}
					onWithdrawFund={onWithdrawFund}
					onResetAction={onResetAction}
					onAddAssets={onAddAssets}
				/>

			</div>
			{
				UpdatePopUp()
			}
			{
				DeletePopUp()
			}
			{
				ViewDetailsPopUp()
			}
			{
				AddPopUp()
			}

			{/* Withdraw */}
			<WithdrawFunds
				withdrawFunds={withdrawFunds}
				setWithdrawFunds={setWithdrawFunds}
				open={openWithdraw}
				onClose={onCloseWithdraw}
				loading={loading}
				onSave={() => onTransactionAction('debit')}
			/>

			{/* Add Asset */}
			<AddAsset
				addAsset={addAsset}
				setAddAsset={setAddAsset}
				openAdd={openAsset}
				onCloseAdd={handleCloseAsset}
				loading={loading}
				onSave={handleSaveAsset}
				updateCoinPrice={updateCoinPrice}
			/>
		</div>
	)
}

export default UsersList;