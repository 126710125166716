import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import ButtonLoader from '../../Helpers/buttonLoader';


const WithdrawFunds = ({ withdrawFunds, setWithdrawFunds, open, onClose, loading, onSave }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Monitor screen width
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            ...(isMobile ? { minWidth: '250px' } : { minWidth: '300px' }),
            padding: '0px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#212F4C',
            border: '0px #28274D solid',
            minWidth: '400px',
        },
    };

    // render
    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            contentLabel="Example Modal"
            style={customStyles}
        >
            <div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}>
                <span className='username'>Withdraw Funds</span>
                <span className='absolute right-2 text-white' onClick={onClose}><Close /></span>
            </div>
            <hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
            <div className='input-container mt-8 mb-8 p-6'>
                <label className="block text-sm text-[#FFF] mt-1">Withdraw Funds</label>
                <div className="flex relative">
                    <select className="adduserInput dropdown text-right" disabled>
                        <option>$</option>
                    </select>
                    <input type="tel" value={withdrawFunds} placeholder="5000" className="adduserInput phone"
                        onChange={(e) => {
                            setWithdrawFunds(e.target.value);
                        }} />
                </div>
            </div>
            <div className='flexRow alignCenter' style={{ padding: '24px' }}>
                {
                    loading ?
                        <div className='save flexRow alignCenter'>
                            <ButtonLoader />
                        </div>
                        :
                        <button
                            onClick={onSave}
                            className='save'
                        >
                            Withdraw Funds
                        </button>
                }
            </div>
        </Modal>
    )
}
export default WithdrawFunds