import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ButtonLoader from '../../Helpers/buttonLoader';

export const defaultAssetData = {
  name: '',
  amount: 0,
  price: '',
}

const AddAsset = ({ addAsset, setAddAsset, openAdd, onCloseAdd, loading, onSave, updateCoinPrice }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [errors, setErrors] = useState({ name: false, amount: false, price: false });
  const [searchText, setSearchText] = useState('');

  const assetList = [
    'Bitcoin', 'Ethereum', 'XRP', 'Solana', 'BNB', 'Dogecoin', 'Cardano', 'USDC', 'Tron', 'Avalanche'
  ];
  // Monitor screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      ...(isMobile ? { minWidth: '250px' } : { minWidth: '300px' }),
      padding: '0px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#212F4C',
      border: '0px #28274D solid',
      minWidth: '500px',
    },
  };

  const handleSave = () => {
    const newErrors = {
      name: !addAsset.name,
      amount: !addAsset.amount,
      price: !addAsset.price,
    };
    setErrors(newErrors);

    // Proceed only if there are no errors
    if (!newErrors.name && !newErrors.amount && !newErrors.price) {
      onSave(addAsset);
    }
  };

  const handleClose = () => {
    setErrors({ name: false, amount: false, price: false })
    onCloseAdd()
  }

  // Filter assets based on search text
  const filteredAssets = assetList.filter(asset =>
    asset?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const handleAssetClick = (asset) => {
    console.log({ ...addAsset, name: asset });

    // Update asset and fetch coin price
    setAddAsset((prev) => ({ ...prev, name: asset }));
    setSearchText(null);
    setErrors((prev) => ({ ...prev, name: false }));
    updateCoinPrice(asset);
  };


  return (
    <Modal
      isOpen={openAdd}
      onRequestClose={onCloseAdd}
      contentLabel="Add Asset"
      style={customStyles}
    >
      <div
        className="flexRow relative"
        style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}
      >
        <span className="username">Add Asset</span>
        <span className="absolute right-2 text-white cursor-pointer" onClick={onCloseAdd}>
          <Close />
        </span>
      </div>
      <hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
      <div className="input-container px-5 py-5 text-[#3CC8C8]">
        {/* Name Field */}
        <label className="block text-sm text-[#3CC8C8] mb-2">Name of coin</label>
        <div className='relative'>
          <input
            type="text"
            value={addAsset.name}
            placeholder="Search for a coin"
            className={`adduserAsset border ${errors.name ? 'border-red-500' : 'border-[#3CC8C8]'
              }`}
            onChange={(e) => {
              const inputValue = e.target.value;
              setSearchText(inputValue); // Update search text
              setAddAsset({ ...addAsset, name: inputValue });
              setErrors({ ...errors, name: false });
            }}
          />
          {searchText && (
            <div
              className="absolute p-1 rounded-xl custom-dropdown-select w-full bg-[#222F4C] h-44 overflow-y-auto scrollbar-none mt-1"
              style={{ top: '100%', left: 0, zIndex: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
            >
              {filteredAssets.map((asset, index) => (
                <div
                  key={index}
                  className="option rounded-xl text-white px-3 py-2.5 mb-1 cursor-pointer bg-[#07142F] hover:bg-[#3CC8C8] hover:text-black"
                  // onClick={() => {
                  //   console.log({ ...addAsset, name: asset })
                  //   setAddAsset({ ...addAsset, name: asset });
                  //   updateCoinPrice(asset)
                  //   setSearchText(null); 
                  //   setErrors({ ...errors, name: false });
                  // }}
                  onClick={() => handleAssetClick(asset)}

                >
                  {asset}
                </div>
              ))}
            </div>
          )}
        </div>

        {errors.name && (
          <p className="text-xs text-red-500 ms-2 ">Please enter the coin name</p>
        )}

        {/* Amount Field */}
        <label className="block text-sm text-[#3CC8C8] mt-4 mb-2">Amount invested</label>
        <input
          type="number"
          value={addAsset.amount}
          placeholder="0"
          className={`adduserAsset border ${errors.amount ? 'border-red-500' : 'border-[#3CC8C8]'
            }`}
          onChange={(e) => {
            setAddAsset({ ...addAsset, amount: e.target.value });
            setErrors({ ...errors, amount: false });
          }}
        />
        {errors.amount && (
          <p className="text-xs text-red-500 ms-2 ">Please enter the amount invested</p>
        )}

        {/* Price Field */}
        <label className="block text-sm text-[#3CC8C8] mt-4 mb-2">Average price</label>
        <input
          readOnly
          type="number"
          value={addAsset.price}
          placeholder="0.50"
          className={`adduserAsset border ${errors.price ? 'border-red-500' : 'border-[#3CC8C8]'
            }`}
          onChange={(e) => {
            setAddAsset({ ...addAsset, price: e.target.value });
            setErrors({ ...errors, price: false });
          }}
        />
        {errors.price && (
          <p className="text-xs text-red-500 ms-2 ">Please enter the average price</p>
        )}
      </div>
      <div
        className="flexRow alignCenter gap-5 text-white"
        style={{ padding: '24px' }}
      >
        {loading ? (
          <div className="save flexRow alignCenter">
            <ButtonLoader />
          </div>
        ) : (
          <>
            <button
              onClick={handleSave}
              className="py-3 px-4 w-full rounded-full text-md"
              style={{
                background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)',
              }}
            >
              Add Asset
            </button>
            <button
              onClick={handleClose}
              className="py-3 px-4 w-full bg-[#37445E]  rounded-full text-md"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddAsset;
