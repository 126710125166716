import { useEffect, useRef, useState } from "react";

const FilterDropdown = ({ setSortingOption, sortingOption, isMobile }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const options = [
        // { id: 'Default', label: 'Default' },
        { id: 'latest', label: 'Latest' },
        { id: 'profit', label: 'Profit (high to low)' },
        { id: 'balance', label: 'Balance (high to low)' },
        // { id: 'Deleted', label: 'Deleted' },
    ];

    const MAX_WORDS = 1;

    function truncateText(text, maxWords) {
        const words = text.split(' ');
        return words.length > maxWords ? words.slice(0, maxWords).join(' ') + '...' : text;
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (optionId) => {
        setSortingOption(optionId);
        setIsOpen(false);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };


    // Close dropdown if clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const selectedOption = options.find((opt) => opt.id === sortingOption);
    const label = selectedOption ? selectedOption.label : 'Sort by';

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
                onClick={toggleDropdown}
                className="inline-flex justify-center w-full md:min-w-[150px] px-4 py-2 text-md font-normal text-white bg-[#37445E] rounded-full shadow-sm"
            >
                {/* {sortingOption ? options.find((opt) => opt.id === sortingOption).label : 'Sort by'} */}
                {isMobile ? truncateText(label, MAX_WORDS) : label}

                <svg
                    className={`w-5 h-5 ml-2 mt-1 -mr-1 ${isOpen ? 'rotate-[180deg]' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            {isOpen && (
                <div
                    onMouseLeave={handleMouseLeave}
                    className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-[#38455F] rounded-md shadow-lg ring-1 ring-[#28274D] focus:outline-none">
                    <div className="p-2 text-sm">
                        {options.map((option) => (
                            <label key={option.id} className="flex items-center px-2 py-1 cursor-pointer">
                                <input
                                    type="radio"
                                    name="options"
                                    value={option.id}
                                    checked={sortingOption === option.id}
                                    onChange={() => handleSelect(option.id)}
                                    className="form-radio h-4 w-4 text-green-500 peer-active:text-gray-300"
                                />
                                <span className="ml-2 text-white">{option.label}</span>
                            </label>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterDropdown;
