import React, { useEffect, useState } from 'react';
import TimePeriodSelector from './TimePeriodSelector';
import { Line } from 'react-chartjs-2';

function ChartPanel({ btcIcon, cryptoData, links, data, options }) {
    const [selected, setSelected] = useState(1);

    return (
        <div className="rounded-3xl w-full">
            {/* Header */}
            <div className="flex items-center gap-2 mb-4">
                <div className="flex flex-col md:flex-row items-center gap-2">
                    <div className='flex gap-2 items-center w-full'>
                        <img src={cryptoData?.logo} alt={cryptoData?.name} className='w-8 h-8' />
                        <span className="text-white mr-5">{cryptoData?.name}/{cryptoData?.symbol}</span>
                        {/* Bitcoin BTC/USDT */}
                    </div>

                    <div className='flex flex-row gap-2 flex-nowrap text-xs md:text-sm'>
                        <p className="text-gray-400 flex gap-1">O <span className='text-[#3BC8C7]'>38573</span></p>
                        <p className="text-gray-400 flex gap-1">H <span className='text-[#3BC8C7]'>38573</span></p>
                        <p className="text-gray-400 flex gap-1">L <span className='text-[#3BC8C7]'>38435</span></p>
                        <p className="text-gray-400 flex gap-1">C <span className='text-[#3BC8C7]'>38573</span></p>
                        <p className="text-gray-400 flex gap-1">
                            <span className='text-[#3BC8C7]'>+476.00</span>
                            <span className='text-[#3BC8C7]'>(+2.76%)</span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Chart Controls */}
            <div className="flex flex-col md:flex-row md:justify-between mb-6">
                <div className="bg-[#2B3D5F] p-1 mb-2 md:mb-0 rounded-lg inline-flex gap-1 w-fit">
                    {links.map((e, i) => (
                        <button
                            key={i}
                            onClick={() => setSelected(e?.value)}
                            className={`
                        px-3 py-1.5 
                        text-sm 
                        rounded-md 
                        transition-colors 
                        duration-200
                        ${selected === e?.value
                                    ? 'bg-[#1E2C49] text-white rounded-full'
                                    : 'text-gray-400 hover:text-gray-300'
                                }
                    `}
                        >
                            {e?.title}
                        </button>
                    ))}
                </div>
                <TimePeriodSelector />
            </div>

            {/* Chart */}
            <div className="relative h-[550px] w-full">
                <Line data={data} options={options} />
            </div>
        </div>
    )
}

export default ChartPanel