import React from 'react';
import placeholderImg from '../../assets/images/trade/biticon.svg';

const AssetsTable = ({ assets }) => {

    console.log(assets)
    return (
        <table className="w-full text-left">
            <thead>
                <tr className="font-medium text-[#3CC8C8]">
                    <th className="py-3">Coin</th>
                    <th className="py-3 text-center">Amount invested</th>
                    <th className="py-3 text-center">Average price</th>
                    <th className="py-3 text-center">Current value</th>
                </tr>
            </thead>
            <tbody>
                {assets.map((asset, index) => (
                    <tr key={index}>
                        <td className="py-3 text-[#fff]">
                            <div className='flex gap-2 items-center'>
                                <img
                                    src={asset.coinLogo}
                                    alt={asset.name}
                                    className="w-8 h-8 mr-2"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholderImg;
                                    }}
                                />
                                <div className='flex flex-col gap-0.5'>
                                    <span className='text-md'>{asset.name}</span>
                                </div>
                            </div>
                        </td>
                        <td className="py-3 text-[#D3D5DB] text-center">${asset.amount.toFixed(3)}</td>
                        <td className="py-3 text-[#D3D5DB] text-center">${asset.avgPrice.toFixed(2)}</td>
                        <td className="py-3 text-[#D3D5DB] text-center">${asset.currentValue.toFixed(2)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AssetsTable;
