import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';

const BalanceChartPanel = ({ data }) => {
    console.log(data, 'data')
    const chartData = {
        series: [
            {
                name: "Balance",
                data: data.map((item) => item.balance),
            },
        ],
        options: {
            chart: {
                type: 'area',
                height: 400,
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                },
            },
            colors: ['#5de6d1'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.6,
                    opacityTo: 0.05,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: data?.map((item) => moment(item.time, "MM-DD-YYYYTHH:mm:ss A").format('MMM D h:mm A')),
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { style: { colors: '#A0A0A0' } },
            },
            yaxis: {
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            grid: {
                show: false,
            },
            // tooltip: {
            //     theme: 'dark',
            //     style: {
            //         fontSize: '12px',
            //         backgroundColor: '#14213D',
            //         color: 'white',
            //         borderRadius: '8px',
            //     },
            //     marker: { show: true },
            //     x: { show: true },
            // },
            tooltip: {
                theme: 'dark',
                x: {
                    formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                        // Show formatted date in tooltip
                        return moment(data[dataPointIndex].time).format('MMM D, YYYY h:mm A');
                    },
                },
                y: {
                    formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                        // Show balance in tooltip
                        return `$${val.toLocaleString()}`;
                    },
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    // Custom tooltip to include additional parameters
                    const item = data[dataPointIndex];
                    return `
                        <div style="background: #14213D; color: white; padding: 10px; border-radius: 8px;">
                            <span>Balance: $${item.balance.toLocaleString()}</span><br/>
                            <span>Profit/Loss: ${'$' + parseFloat(item.items.profitLoss.replace(/[^\d.-]/g, ''))}</span>
                        </div>
                    `;
                },
            }
        },
    };

    return (
        <div>
            <Chart options={chartData.options} series={chartData.series} type="area" height={400} />
        </div>
    );
};

export default BalanceChartPanel;
