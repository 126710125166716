import React from 'react';

const PageLoader = () => {
    return (
        <div className="fixed top-[100px] left-0 w-full h-[90%] marker:bg-opacity-80 backdrop-blur-md flex justify-center items-center z-50">
            <div className="relative">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-purple-500 border-solid"></div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-lg font-medium">
                    Loading...
                </div>
            </div>
        </div>
    );
};

export default PageLoader;