import { useEffect, useState } from "react";
import ButtonLoader from '../../Helpers/buttonLoader';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import { getFirstName, getUserName } from "../../services/common.utils";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import TokenParser from "../../Helpers/tokenParser";
const Information = () => {
    const navigate = useNavigate();
    const [isEdit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    let token = Cookies.get("token");
    let details = TokenParser(token);
    const fetchDetails = async (timeframeValue = 'recent') => {
        let token = Cookies.get("token");
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKEND}getUserProfile?timeframe=${timeframeValue}&id=${details.payload.userId}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                setEmail(apiResponse?.data?.email);
                setPhoneNumber(apiResponse.data?.phoneNumber);
                setName(apiResponse.data?.firstName + ' ' + apiResponse.data?.lastName);
                setFirstName(apiResponse.data?.firstName)
                setLastName(apiResponse.data?.lastName)
            })
            .catch((apiError) => {
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });
    };

    useEffect(() => {
        fetchDetails();
    }, [])

    const validateInputs = () => {
        if (!firstName) return 'First name is required';
        if (!lastName) return 'Last name is required';
        if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return 'Please enter a valid email';
        if (!phoneNumber || !/^\d{10,12}$/.test(phoneNumber)) {
            return 'Please enter a valid 10-digit phone number';
        }
        return null;
    };

    const handleSave = async () => {
        const validationError = validateInputs();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        setLoading(true);
        setErrorMessage('');

        try {
            const token = Cookies.get('token');
            const formData = new FormData();
            formData.append('request', JSON.stringify({
                firstName,
                lastName,
                email,
                phoneNumber
            }));

            const response = await axios.post(`${process.env.REACT_APP_BACKEND}updateUserProfile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            toast.success('Profile updated successfully!', { theme: 'dark' });
            await fetchDetails();
            setEdit(false)
        } catch (error) {
            const message = error.response?.data?.message || 'An error occurred while updating profile';
            setErrorMessage(message);
        } finally {
            setLoading(false);
        }
    };


    return (

        <div className="m-h-[80vh]">
            <p className='mb-5 font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>
            <div className="flex flex-col md:flex-row gap-8 w-full">

                <div className='w-full flex flex-col gap-16 justify-between md:justify-evenly items-center md:w-[30%] bg-[#212F4C] p-8 rounded-2xl'>
                    <div className="flex flex-col items-center gap-4 md:gap-10 text-[#FFFFFF] text-2xl font-semibold">
                        <div className="letter-container">{getFirstName()?.charAt(0)?.toUpperCase()}</div>
                        <span>{getUserName()}</span>
                    </div>
                    <div className="button-container">
                        <button className="loginReset" onClick={() => navigate('/user/reset-password')}>Password Reset</button>
                    </div>
                </div>
                <div className="md:w-[70%] common">
                    {
                        !isEdit ?
                            <div className="form-container bg-[#222F4C] md:min-h-[65vh]">
                                <div className="record-header">
                                    <div className="record-title text-md">User Information</div>
                                    <button onClick={() => { setEdit(true) }} className="edit-btn">Edit</button>
                                </div>
                                <div>
                                    <div className="hidden md:block details-header" style={{ color: ' #3CC8C8' }}>
                                        Full Name
                                    </div>
                                    <div className="details-value" style={{ color: '#3CC8C8' }}>
                                        {name || ''}
                                    </div>
                                </div>
                                <div>
                                    <div className="details-header">
                                        Email Address
                                    </div>
                                    <div className="details-value">
                                        {email || ''}
                                    </div>
                                </div>
                                <div>
                                    <div className="details-header">
                                        Phone Number
                                    </div>
                                    <div className="details-value">
                                        {phoneNumber || ''}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="form-container">
                                <div className="record-header">
                                    <div className="record-title text-md">User Information</div>
                                    <button className="text-slate-300" onClick={() => setEdit(false)}><Close /></button>
                                </div>
                                <div className="md:flex gap-5">
                                    <div className='input-container'>
                                        <span className='text-[#3CC8C8]'>First Name</span>
                                        <input type='text' value={firstName} placeholder="First Name" className='adduserInput text-[#3CC8C8] focus:border-[#3CC8C8]'
                                            onChange={(e) => {
                                                setFirstName(e.target.value);
                                                setErrorMessage('');
                                            }}
                                        />
                                    </div>

                                    <div className='input-container'>
                                        <span className='text-[#3CC8C8]'>Last Name</span>
                                        <input type='text' value={lastName} placeholder="Last Name" className='adduserInput text-[#3CC8C8] focus:border-[#3CC8C8]'
                                            onChange={(e) => {
                                                setLastName(e.target.value);
                                                setErrorMessage('');
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='input-container'>
                                    <label className="block text-sm text-white mb-2">Email Address</label>
                                    <input type="email" value={email} placeholder="Email address" className="adduserInput"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setErrorMessage('');
                                        }}
                                    />
                                </div>

                                <div className='input-container'>
                                    <label className="block text-sm text-white mb-2">Phone Number</label>
                                    <input type="tel" value={phoneNumber} placeholder="Enter number" className="adduserInput"
                                        onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                            setErrorMessage('');
                                        }}
                                    />
                                </div>

                                {errorMessage && <p className="text-red-500">{errorMessage}</p>}

                                <div className="btn-container">
                                    {
                                        loading ?
                                            <div className='AddUserButton'>
                                                <ButtonLoader />
                                            </div>
                                            :
                                            <div>
                                                <button onClick={handleSave} className='AddUserButton'>Save</button>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>


    )
}

export default Information;