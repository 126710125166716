import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import ButtonLoader from '../../Helpers/buttonLoader';
import zIndex from '@mui/material/styles/zIndex';
import { numericValue } from '../../services/common.utils';
import TypeDropdown from '../../Screens/Users/TypeDropdown';


const UpdateBalance = ({ currentUser, setCurrentUser, openUpdate,
    onCloseUpdate, onUpdateBalanceAction, selectType, handleDropdownChange,
    loading }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Monitor screen width
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            ...(isMobile ? { minWidth: '250px' } : { minWidth: '300px' }),
            padding: '0px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#212F4C',
            border: '0px #28274D solid',
            minWidth: '400px',
        },
    };

    const options = [
		{ id: 'profit', label: 'Profit' },
		{ id: 'loss', label: 'Loss' },
	];
    
    // render
    return (
        <Modal
            isOpen={openUpdate}
            onRequestClose={onCloseUpdate}
            contentLabel="Example Modal"
            style={customStyles}
        >
            <div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px', paddingBottom: '16px', borderBottom: '1px solid #28274D' }}>
                <span className='username'>Update Balance</span>
                <span className='absolute right-2 text-white' onClick={onCloseUpdate}><Close /></span>
            </div>
            <hr style={{ border: 'none', margin: '10px 0' }} />
            <div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
                <span className='fieldName'>Profit/ Loss</span>
                {/* <select value={selectType}
                    className='dropDownInput'
                    onChange={handleDropdownChange}>
                    <option value="" disabled>Select</option>
                    <option value="Profit">Profit</option>
                    <option value="Loss">Loss</option>
                </select> */}
				<TypeDropdown options={options} handleDropdownChange={handleDropdownChange} selectedOption={selectType} />

            </div>
            <div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
                <span className='fieldName'>Amount</span>
                <input
                    type="number"
                    className='numberInput'
                    value={numericValue(currentUser.profitLossAmount) ? numericValue(currentUser.profitLossAmount) : "0"}
                    onChange={(e) => {
                        let inputValue = e.target.value;
                        if (/^\d*\.?\d*$/.test(inputValue)) {
                            let temp = {
                                ...currentUser,
                                profitLossAmount: inputValue,
                            };
                            setCurrentUser(temp);
                        }
                    }}
                ></input>
            </div>
            <div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
                <span className='fieldName'>Cycle days</span>
                <input
                    type='number'
                    className='numberInput'
                    value={currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0}
                    onChange={(e) => {
                        let inputValue = e.target.value;
                        let temp = {
                            id: currentUser.id,
                            userName: currentUser.userName,
                            balance: currentUser.balance,
                            profitLossAmount: currentUser.profitLossAmount,
                            profitLossType: currentUser.profitLossType,
                            openTrades: currentUser.openTrades,
                            subscriptionPeriod: inputValue
                        };
                        setCurrentUser(temp);
                    }}
                ></input>
            </div>
            <div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
                <span className='fieldName'>Open Trades</span>
                <input
                    type='number'
                    className='numberInput'
                    value={currentUser.openTrades ? currentUser.openTrades : 0}
                    onChange={(e) => {
                        let inputValue = e.target.value;
                        let temp = {
                            id: currentUser.id,
                            userName: currentUser.userName,
                            balance: currentUser.balance,
                            profitLossAmount: currentUser.profitLossAmount,
                            profitLossType: currentUser.profitLossType,
                            openTrades: inputValue,
                            subscriptionPeriod: currentUser.subscriptionPeriod
                        };
                        // console.log(inputValue);
                        setCurrentUser(temp);
                    }}
                ></input>
            </div>
            <div className='flexRow alignCenter' style={{ marginTop: 15, marginBottom: 15 }}>
                {
                    loading ?
                        <div className='save flexRow gap-1 alignCenter rounded-xl min-w-[150px]'>
                            <ButtonLoader />
                            <span className='text-sm'>Please wait ...</span>
                        </div>
                        :
                        <button
                            onClick={onUpdateBalanceAction}
                            className='save rounded-xl min-w-[150px]'
                        >
                            Save
                        </button>
                }
            </div>
        </Modal>
    )
}
export default UpdateBalance