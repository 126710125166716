import React from 'react';

function BalanceCard({ title, value, previousBalance = null, days = null, trades = null, height = 100, type = 'value', selectedValue, handleDropdownChange }) {
    // if (!value && !trades && !days) return <></>
    let isPositive = Number(value) >= 0 || days >= 0 || trades >= 0 || trades === '0'

    return (
        <div className="bg-[#2C3A58] rounded-2xl p-6 flex justify-between flex-col">
            <div className="count-label">{title}</div>
            <div className="value-container flex flex-col justify-end" style={{ height: height }}>
               {type === 'pnl' && <div className={`text-xs font-bold  md:text-end py-2`}>
                    <select
                        value={selectedValue}
                        onChange={handleDropdownChange}
                        className='bg-[#212C45] outline-none text-white p-2 px-4 rounded-xl border-px border-[#212C45]'>
                        <option value="recent" selected>Last Transcation</option>
                        <option value="today">Today</option>
                        <option value="1 week">1 week</option>
                        <option value="1 month">1 month</option>
                        <option value="all">All time</option>
                    </select>
                </div>}
                <span className={`amount ${trades || days ? 'text-white' :
                    type === 'pnl' ? (Number(value)) >= 0 ? 'text-[#3CC8C8]' : 'text-[#FD3549]' : (isPositive) ? 'text-[#3CC8C8]' : 'text-[#FD3549]'}`}>
                    {trades > 0 ? trades : days ? days : '$' + value?.toLocaleString()}
                </span>
                {previousBalance && <p className='text-[#D4D8DE]'>Previous Balance ${previousBalance}</p>}
                {days && <span className='text-white font-medium'>days</span>}
            </div>
        </div>
    );
}

export default BalanceCard;
