import { ChevronDown, ChevronUp } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';

const CommonDropdown = ({ options, selectedValue, onChange, minWidth = 100, classes = 'bg-transparent border border-[#fff]' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionClick = (value) => {
        onChange(value);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end" ref={dropdownRef}>
            {/* Trigger Button */}
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`mt-0 flex gap-2 justify-between outline-none text-white p-2 px-4 rounded-full w-fit capitalize ${classes}`} style={{minWidth: minWidth}}>
                {selectedValue || 'Select'}
                {!isOpen ? <ChevronDown size={14} /> : <ChevronUp size={14} />}
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="absolute right-0 top-full mt-2 bg-[#EFEFF4] shadow-lg rounded-xl p-2 z-10 text-center">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => handleOptionClick(option.value)}
                            className={`cursor-pointer p-2 rounded-full text-[#8A8A8F] hover:border hover:border-[#3AB3D1] ${
                                selectedValue === option.value ? 'text-blue-500 font-bold' : ''
                            }`}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CommonDropdown;
