import React, { useEffect, useState } from 'react'
import UserInfoCard from './UserInfoCard'
import { filterAndFormatData, getUserData, groupByHourBalanceChart, updateCardsData } from '../../services/common.utils'
import BalanceChart from './BalanceChart'
import BotPerformanceCard from './BotPerformanceCard'
import dummyTrade from '../../assets/images/user/dummyTrade.png'
import WalletActivity from '../../Components/Analytics/WalletActivity'
import Cookies from 'js-cookie'
import TokenParser from '../../Helpers/tokenParser'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import BalanceCard from '../../Components/Common/BalanceCard'
import HangingHistogram from '../../Components/BarPlot'
import PageLoader from '../../Components/Common/PageLoader'
import ActiveTradeStats from '../../Components/ActiveTrades/ActiveTradeStats'
import QuickAnalyticsUser from '../../Components/Analytics/QuickAnalyticsUser'
import MemuTrades from '../../Components/ActiveTrades/MemuTrades'
import botperformanceImg from '../../assets/images/botPerformance.png'
import WalletTable from '../../Components/Wallet/WalletTable'
import graph from '../../assets/images/market/graph.svg'
import graphRed from '../../assets/images/graphRed.svg'

function UserDashboard() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const navigate = useNavigate();

    const userData = getUserData()
    const botperformanceData = [
        { title: 'Ethereum', link: 'https://www.tradingview.com/chart/ScmGQXP1/?symbol=COINBASE%3AETHUSD', img: botperformanceImg },
        { title: 'Solana', link: 'https://www.tradingview.com/chart/ScmGQXP1/?symbol=CRYPTOCAP%3ASOL', img: botperformanceImg },
    ]

    const [walletData, setWalletData] = useState([])
    const [loading, setLoading] = useState(false)
    const [adminName, setAdminName] = useState('');
    const [userName, setUserName] = useState('');
    const [PNLType, setPNLType] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [updatedAt, setUpdatedAt] = useState("");
    const [previousBalance, setPreviousBalance] = useState("");
    const [openTrades, setOpenTrade] = useState(0);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState(0);
    // const [openTrade, setOpenTrade] = useState(false);
    const [PNL, setPNL] = useState(0);
    const [userId, setUserId] = useState(null)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedChartDropdown, setSelectedChartDropdown] = useState('recent')
    const [balanceData, setBalanceData] = useState([])
    const [chartData, setChartData] = useState([])
    const [initialInvestment, setInitialInvestment] = useState(0)
    const [selectedValue, setSelectedValue] = useState("recent");
    const [cardsData, setCardData] = useState([
        {
            id: 'balance',
            title: 'Balance',
            value: '$0',
            change: '0',
            bgColor: 'bg-[#3CC8C8]',
            textColor: 'text-black',
            titleColor: '#666666',
            onClick: () => console.log('Volume card clicked'),
            isActive: true,
            // dropdownValue: [{ value: 2024, label: 2024 }],
            // selectedValue: 2024
        },
        {
            id: 'totalProfitLoss',
            title: 'Total Profit / Loss',
            value: '$0',
            change: '0',
            bgColor: 'bg-[#7E6CFF]',
            textColor: 'text-white',
            titleColor: 'white',
            onClick: () => console.log('Revenue card clicked'),
            hasFilter: true,
            selectedValue: 'recent',
        },
        {
            id: 'openTrades',
            title: 'Open Trades/Cycles',
            value: '0',
            days: 0,
            bgColor: 'bg-[#D02AFD]',
            textColor: 'text-white',
            titleColor: 'white',
            onClick: () => console.log('Users card clicked'),
            // hasFilter: true,
            // selectedValue: 'today',
        },
    ]);
    const [walletLoading, setWalletLoading] = useState(false)

    const recordsPerPage = 5;

    const handleDropdownStatChange = (id, newValue) => {
        setCardData((prevCards) =>
            prevCards.map((card) =>
                card.id === id
                    ? { ...card, selectedValue: newValue }
                    : card
            )
        );
    };

    let token = Cookies.get("token");
    let details = TokenParser(token);

    const [analyticsStat, setAnalyticsStat] = useState([
        {
            id: 'balance',
            title: 'Assets Balance',
            value: '$0',
            change: '0%',
            gradientBg: 'linear-gradient(272.44deg, #0EEEA8 4.31%, #7770FF 129.99%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
            buttonText: 'View details',
            isButtonVisible: true,
            onClickAction: () => navigate('/user/assets')
        },
        {
            id: 'funds',
            title: 'Invested Funds',
            value: '$0',
            gradientBg: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)',
            isButtonVisible: false,
        },
    ]);

    const start = (currentPage - 1) * recordsPerPage + 1;
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    headers["userId"] = userId;


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchDetails = async (timeframeValue = "recent") => {
        setLoading(true);

        await axios.get(process.env.REACT_APP_BACKEND + `getUserProfile?timeframe=${(timeframeValue ? timeframeValue : selectedValue)}&id=${userId ? userId : details?.payload?.userId}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                setUserName(apiResponse.data?.username || 0);
                setTotalBalance(apiResponse.data?.balance || 0);
                setPreviousBalance(apiResponse.data?.previousBalance || 0);
                setUpdatedAt(apiResponse.data?.lastUpdatedOn || 0);
                setPNLType(apiResponse.data?.profitLossType || 0);
                setSubscriptionPeriod(apiResponse.data?.subscriptionPeriod || 0);
                setOpenTrade(apiResponse.data?.openTrades || 0);
                setInitialInvestment(apiResponse.data?.initialInvestmentAmount || 0);
                setPNL(
                    apiResponse.data?.profitLossAmount
                );

                updateCardsData(apiResponse.data, cardsData, setCardData, analyticsStat, setAnalyticsStat)
                setLoading(false);
            })
            .catch((apiError) => {
                setLoading(false);
                if (apiError?.response?.status === 401) {
                    navigate("/login");
                }
            });

        await getWalletRecords()


        // headers["userId"] = userId;
        // await axios.get(process.env.REACT_APP_BACKEND + "history", { headers: headers, })
        //     .then((apiResponse) => {
        //         setWalletData(apiResponse.data?.stats);
        //         const stats = groupByHourBalanceChart(apiResponse.data.stats);
        //         console.log(stats, 'stat')
        //         setBalanceData(stats);
        //         setRecordsFiltered(apiResponse.data?.recordsFiltered)
        //         setChartData(filterAndFormatData(apiResponse.data?.stats, selectedChartDropdown))
        //         setLoading(false);
        //     })
        //     .catch((apiError) => {
        //         if (apiError.response.status === 401) {
        //             navigate("/login");
        //         }
        //     });
    };

    const getWalletRecords = async () => {
        setWalletLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND}history?start=${start}`, { headers: headers, })
            .then((apiResponse) => {
                setWalletData(apiResponse.data?.stats?.slice(0, 5));
                setRecordsFiltered(apiResponse.data?.recordsFiltered)
                const stats = groupByHourBalanceChart(apiResponse.data.stats);
                setBalanceData(stats);
                setChartData(filterAndFormatData(apiResponse.data?.stats, selectedChartDropdown))

                // setChartData(filterAndFormatData(apiResponse.data?.stats, balanceChartType))
                setWalletLoading(false);
            })
            .catch((apiError) => {
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
                setWalletLoading(false);
            });
    }

    useEffect(() => {
        if (start) {
            getWalletRecords()
        }
    }, [start])

    useEffect(() => {
        const leftDiv = document.querySelector('.left-div');
        const rightDiv = document.querySelector('.right-div');
        if (rightDiv && leftDiv) {
            leftDiv.style.minHeight = `${rightDiv.offsetHeight}px`;
        }
    }, [walletData]); // Update when walletData changes

    // init
    useEffect(() => {
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setAdminName(firstLetter);
            setUserId(details.payload.userId)
            // setUserId('0021aa7dd5ab5b9e1ff171c80da4afb7690ad983485bfe616d7f0b13ae8207af')
        }

        if (userId) {
            fetchDetails();
            // fetchChartData()

        }
    }, [userId]);

    useEffect(() => {
        if (cardsData?.[1]?.selectedValue) {
            setSelectedValue(cardsData?.[1]?.selectedValue)
        }
    }, [cardsData?.[1]?.selectedValue])

    useEffect(() => {
        if (selectedChartDropdown) {
            let filteredData = filterAndFormatData(walletData, selectedChartDropdown)
            setChartData(filteredData)
        }
    }, [selectedChartDropdown])


    // conver format
    const formatCurrency = (value) => { return value ? Number(value?.toString()?.replace(/[^0-9.-]+/g, "")) : 0 }

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        if (selectedValue) {
            fetchDetails(selectedValue)
        }
    }, [selectedValue])

    // console.log(walletData, 'walletData')
    // render
    return (
        loading ? (
            <PageLoader />
        ) :
            <div className="text-white">
                <p className='font-semibold text-2xl capitalize text-white mb-5 text-center md:text-start'>
                    Hello <span className='text-[#3CC8C8]'>{userData?.username}</span>
                </p>
                {/* Info */}
                <UserInfoCard name={userData?.username} email={userData?.email} />

                {/* Balance Chart */}
                {/* <div className='my-5'>
                    <BalanceChart totalBalance={totalBalance} data={balanceData} />
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-10 gap-5 my-5">
                    <div className="md:col-span-7 col-span-1">
                        {/* QuickAnalytics */}
                        <QuickAnalyticsUser
                            stats={cardsData}
                            analyticsStat={analyticsStat}
                            handleDropdownStatChange={handleDropdownStatChange}
                            walletData={walletData} />
                    </div>

                    <div className="md:col-span-3 col-span-1">
                        {/* ActiveTradeStats */}
                        <ActiveTradeStats
                            totalTrades={cardsData.find(e => e.id === 'balance')?.value}
                            tradePercent={cardsData.find(e => e.id === 'balance')?.change ? cardsData.find(e => e.id === 'balance')?.change + '%' : '0%'}
                        />
                    </div>
                </div>
                {/* Bot Performance */}
                <div className='flex flex-col md:flex-row w-full gap-5'>
                    {botperformanceData?.map((e, index) => (
                        <div className='md:w-1/2 w-full' key={index}>
                            <BotPerformanceCard title={e.title} link={e.link} img={e.img} />
                        </div>
                    ))}
                </div>

                {/* Weekly Activity / Stats */}
                <div className='flex flex-col md:flex-row gap-5 mt-5'>
                    {/* left */}
                    <div className='md:w-[65%] w-full left-div h-fit'>
                        <div className="bg-[#202F4C] rounded-2xl p-5 md:p-8 shadow-lg text-white md:h-[100%]">
                            <div className="flex flex-col gap-5 md:gap-10 items-center md:items-start mb-7">
                                <span className="text-2xl font-semibold">Wallet Activity</span>
                                <div className="table-btn-container flex-row items-center">
                                    <button className="AddUserButton w-fit">Recent Transactions</button>
                                </div>
                            </div>
                            <WalletTable
                                currentRecords={walletData}
                                isMobile={isMobile}
                                graph={graph}
                                graphRed={graphRed}
                                loading={walletLoading}
                                recordsPerPage={recordsPerPage}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                recordsFiltered={recordsFiltered}
                                extraClass="md:[h-100%]"
                            />
                        </div>
                    </div>

                    {/* right */}
                    <div className='md:w-[35%] w-full flex flex-col gap-4 right-div h-full'>
                        {walletData.length === 0 ? (
                            <div className="bg-[#202F4C] rounded-2xl p-8 shadow-lg text-white h-full">
                                <div className="flex bg-transparent h-full flex-col items-center justify-center w-full py-8 md:min-h-[500px]">
                                    <div className="text-2xl md:text-4xl text-[#32CCCB] font-semibold">No History Available</div>
                                    <p className="text-sm md:text-lg mt-2 text-gray-400">Your transaction history will appear here.</p>
                                </div>
                            </div>
                        ) :
                            // <>
                            //     <div className='md:w-3/3'>
                            //         <BalanceCard title='Balance' value={formatCurrency(totalBalance)}
                            //             previousBalance={formatCurrency(previousBalance)} />
                            //     </div>
                            //     <div className='flex flex-col md:flex-row gap-4'>
                            //         <div className='md:w-1/2'>
                            //             <BalanceCard title='Invested Amount' value={formatCurrency(initialInvestment)} />
                            //         </div>
                            //         <div className='md:w-1/2'>
                            //             <BalanceCard title='Profit &  Loss' value={formatCurrency(PNL)} type='pnl'
                            //                 selectedValue={selectedValue} handleDropdownChange={handleDropdownChange} />
                            //         </div>
                            //     </div>
                            //     <div className='flex flex-col md:flex-row gap-4'>
                            //         <div className='md:w-1/2'>
                            //             <BalanceCard title='Open Trades' trades={openTrades || '0'} height={150} />
                            //         </div>
                            //         <div className='md:w-1/2'>
                            //             <BalanceCard title='Cycle' days={5} height={150} />
                            //         </div>
                            //     </div>
                            //     {/* Chart */}
                            //     <HangingHistogram height={285} radius={'16px'} bg={'#2C3A58'} width={550} setSelectedChartDropdown={setSelectedChartDropdown}
                            //         selectedType={selectedChartDropdown}
                            //         setChartData={setChartData}
                            //         data={chartData} />
                            // </>

                            <MemuTrades />
                        }
                    </div>
                </div>
            </div>
    )
}

export default UserDashboard