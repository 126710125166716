import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { formatToUSCurrency } from '../../services/common.utils';
import CommonDropdown from '../Common/CommonDropdown';

const StatsCard = ({
    title = "Total Trades",
    value = "$0",
    change = null,
    timeframe = "from last month",
    bgColor = "bg-teal-400",
    textColor = "text-black",
    titleColor = '#666666',
    onClick = () => { },
    isActive = false,
    hasFilter = false,
    dropdownValue = [],
    selectedValue = null,
    days = null,
    handleDropdownChange,
    isUser = false,
    id
}) => {

    const dropdownOptions = [
        { value: 'recent', label: 'Last Transaction' },
        { value: 'today', label: 'Today' },
        { value: '1 week', label: 'Last 7 Days' },
        { value: '1 month', label: 'Last 30 Days' },
        { value: 'all', label: 'All Time' },
    ];
    
    const ondropdownAction = (value) => {
        handleDropdownChange(id, value)
    }

    // render
    return (
        <div
            className={`${bgColor} flex min-h-[6rem] md:min-h-[8.2rem] justify-between items-center md:block rounded-xl p-4 relative cursor-pointer group transition-all duration-300 hover:shadow-lg`}
        // onClick={onClick}
        >
            <div className={`flex flex-col gap-1 md:gap-0 ${!change ? 'md:h-full' : 'md:h-auto'} md:justify-center`}>
                {/* Title */}
                <h3 className={`text-[${titleColor}] text-md md:text-lg mb-2 md:mb-1 font-normal relative`}>
                    {title}
                </h3>

                {hasFilter ?
                    // <select
                    //     value={selectedValue}
                    //     onChange={(e) => handleDropdownChange(e.target.value)}
                    //     className='bg-transparent md:absolute right-2 top-3 outline-none text-white p-1 px-3 rounded-full border border-[#fff] w-[100px]'>
                    //     <option value="recent" selected>Recent</option>
                    //     <option value="today">Today</option>
                    //     <option value="1 week">1 week</option>
                    //     <option value="1 month">1 month</option>
                    //     <option value="all">All time</option>
                    // </select>
                    <div className='text-xs absolute top-3 right-2'>
                        <CommonDropdown
                            options={dropdownOptions}
                            selectedValue={selectedValue}
                            onChange={ondropdownAction}
                        />
                    </div>
                    : isUser && hasFilter && <select
                        value={selectedValue}
                        onChange={handleDropdownChange}
                        className='bg-transparent md:absolute right-2 top-3 outline-none text-white p-1 px-3 rounded-full border border-[#fff] w-fit'>
                        {dropdownValue?.map(e => <option value={e.value}>{e.label}</option>)}
                    </select>}

                {/* Value */}
                <div className={`${textColor} text-xl md:text-2xl font-medium mb-4`}>
                    {value ? formatToUSCurrency(value) : 0}
                </div>
            </div>


            {/* Change indicator */}
            {change ?
                //  <div className="text-sm inline-flex items-center bg-white rounded-full px-4 py-2 space-x-2 h-fit">
                //     <span className={`${Number(change) > 0 ? 'text-green-500' : 'text-red-500'} font-medium`}>
                //         {change + '%'}
                //     </span>
                //     <span className="text-gray-500">
                //         {timeframe}
                //     </span>
                // </div> 
                <></> :
                days && <div className="inline-flex items-center bg-white rounded-full px-4 py-2 space-x-2 h-fit">
                    <span className="text-[#8A8A8F] text-sm">
                        {days} days
                    </span>
                </div>}

            {/* Arrow button */}
            {/* {isActive && <div className="hidden md:block absolute -right-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 transition-opacity duration-300">
                <ChevronRight className="w-5 h-5 text-gray-600" />
            </div>} */}
        </div>
    );
};

export default StatsCard