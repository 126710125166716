import logo from '../assets/images/logo.svg';
import fb from '../assets/images/fb.png';
import instagram from '../assets/images/instagram.png';
import twitter from '../assets/images/twitter.png';
import LocationIcon from '../assets/images/landing/location-icon.png';
import MailIcon from '../assets/images/landing/mail-icon.png';
import PhoneIcon from '../assets/images/landing/phone-icon.png';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';

const Footer = ({handleClick}) => {

    const GradientTextField = styled(TextField)(({ theme }) => ({
        "& .MuiOutlinedInput-root": {
          borderRadius: "24px", // Rounded corners
          background: "linear-gradient(180deg, rgba(145, 0, 255, 0) 0%, rgba(145, 0, 255, 0.17) 100%)", // Gradient background
          color: "#fff", // White text color
          "& fieldset": {
            borderColor: "transparent", // No border color
            },
            "&:hover fieldset": {
            borderColor: "transparent", // No border color on hover
            },
            "&.Mui-focused fieldset": {
            borderColor: "transparent", // No border color when focused
            },
        },
        "& .MuiInputBase-input": {
          color: "#fff", // White text color
        },
        "& .MuiInputLabel-root": {
          color: "rgba(255, 255, 255, 0.5)", // Placeholder color
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#fff", // Placeholder color when focused
        },
      }));
    
    return (
        <div>
            <div className='w-[100%] mt-12' style={{border: '1.31px solid #7770FF'}}></div>
            <div className='md:p-10 below-md:py-10 flex md:flex-row below-md:flex-col md:justify-between gap-10 below-md:gap-3'>
                <div className='flex flex-col justify-between below-md:justify-center below-md:p-4  below-md:items-center md:w-[30%]'>
                    <div>
                        <img src={logo} alt='logo' className="h-10" ></img>
                    </div>
                    <div className='below-md:hidden'>
                        <span className='text-white text-opacity-50'>Connect with Havok Labs to stay updated on blockchain and tokenomics. Follow us on social media or subscribe to our newsletter for industry insights and exclusive offers.                    </span>
                    </div>
                </div>
                <div className='below-md:hidden flex flex-col below-md:flex-wrap gap-3 below-md:p-4 below-md:gap-3'>
                    <span className='text-[#FFF] font-bold'>Quick links</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('home', "section1")}}
                    >Home</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('aboutus', "section2")}}
                    >About Us</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('services', "section3")}}
                    >Services</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('solutions', "section4")}}
                    >Solutions</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('blog', "section5")}}
                    >Blog</span>
                    <span className='text-[#818795] font-normal text-sm cursor-pointer'
                    onClick={() => {handleClick('contact', "section7")}}
                    >Contact</span>
                </div>
                <div className='md:hidden below-md:p-4 below-md:px-8 flex flex-col gap-3'>
                    <span className='text-[#FFF] font-bold'>Quick links</span>
                    <div className='flex flex-row below-md:flex-wrap gap-3'>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('home', "section1")}}
                        >Home</span>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('aboutus', "section2")}}
                        >About Us</span>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('services', "section3")}}
                        >Services</span>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('solutions', "section41")}}
                        >Solutions</span>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('blog', "section5")}}
                        >Blog</span>
                        <span className='text-[#818795] font-normal text-sm cursor-pointer'
                        onClick={() => {handleClick('contact', "section7")}}
                        >Contact</span>
                    </div>
                </div>

                <div className='flex flex-col below-md:flex-wrap gap-3 below-md:p-4 below-md:px-8 below-md:gap-3'>
                    <span className='text-[#FFF] font-bold'>Contact</span>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <div>
                            <img src={LocationIcon} alt='' />
                        </div>
                        <div>
                            <span className='text-[#818795] font-normal text-sm'>1203 south white chapel suite 100 <br></br>Southlake, Texas 76092</span>
                        </div>
                    </div>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <div>
                            <img src={MailIcon} alt='' />
                        </div>
                        <div>
                            <span className='text-[#818795] font-normal text-sm'>info@havoklabs.io</span>
                        </div>
                    </div>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <div>
                            <img src={PhoneIcon} alt='' />
                        </div>
                        <div>
                            <span className='text-[#818795] font-normal text-sm'>945-279-2090</span>
                        </div>
                    </div>
                </div>
                <div className='md:hidden below-md:p-4'>
                    <span className='text-white '>Connect with Havok Labs to stay updated on blockchain and tokenomics. Follow us on social media or subscribe to our newsletter for industry insights and exclusive offers.                    </span>
                </div>
                <div className='flex flex-col justify-between items-end below-md:items-center'>
                    <div className='flex flex-row gap-3 below-md:hidden'>
                        <img src={fb} alt='fb' />
                        <img src={instagram} alt='instagram' />
                        <img src={twitter} alt='twitter' />
                    </div>
                    <div>
                        <div className='below-md:text-center'>
                            <span className='gradient-text'>Newsletter Sign-Up:</span>
                        </div>
                        <div className='flex flex-row items-center gap-5'>
                            <div>
                                <GradientTextField 
                                    variant="outlined"
                                    placeholder="Email"
                                    InputProps={{
                                    style: { color: "#fff" }, // White text color
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <Button
                                    className='contact-btn'
                                    style={{width:'fit-content'}}
                                    >
                                        Subscribe
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-3 md:hidden mt-[20px] justify-center'>
                    <img src={fb} alt='fb' />
                    <img src={instagram} alt='instagram' />
                    <img src={twitter} alt='twitter' />
                </div>
            </div>
        </div>
    )
}

export default Footer;