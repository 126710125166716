import { useEffect, useState } from 'react';
import { getFirstName, transformToAssets } from '../../services/common.utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import AssetsTable from './AssetsTable';
import TokenParser from '../../Helpers/tokenParser';
import Cookies from 'js-cookie'
import axios from 'axios';
import PageLoader from '../../Components/Common/PageLoader';

const AssetDetails = () => {

    const [assetsBalance, setAssetsBalance] = useState(0);
    const [percentChange, setPercentChange] = useState(0);
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [assets, setAssets] = useState([]);

    let token = Cookies.get("token");
    let details = TokenParser(token);



    const fetchAssets = async () => {
        let path = `${process.env.REACT_APP_BACKEND}user_assets?userId=${userId}`
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        setLoading(true)
        await axios.get(path, {
            headers: headers,
        })
            .then((apiResponse) => {
                let data = apiResponse?.data
                setAssetsBalance('$' + Number(data?.assetsBalance).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }));
                setAssets(transformToAssets(data?.assetsDTOS))
                setPercentChange((data?.assetsPctByTimeFrame)?.toFixed(3))
            })
            .catch((apiError) => {
                console.log(apiError)
            })
            .finally(() =>
                setLoading(false)
            );
    }


    useEffect(() => {
        if (details) {
            setUserId(details.payload.userId)
        }

        if (userId) {
            fetchAssets();
        }
    }, [userId]);

    // render
    return (
        <div className="h-full text-white md:p-6 md:h-screen">
            {loading && <PageLoader />}
            <p className='mb-10 font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>

            <div className="bg-[#45BAD0] md:min-h-[10rem] text-center p-4 rounded-t-2xl w-full mx-auto">
                <div className="flex flex-col items-center justify-between gap-2 py-10">
                    <h2 className="text-2xl font-bold text-white">Assets Balance</h2>
                    <div className="text-5xl font-bold text-white">{assetsBalance}</div>
                    <div
                        className={`flex items-center gap-2 font-bold ${percentChange >= 0 ? 'text-[#75F484]' : 'text-red-500'
                            }`}
                    >
                        {percentChange >= 0 ? <ChevronUp /> : <ChevronDown />}
                        {Math.abs(percentChange)}%
                        {/* (1d) */}
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className='p-5'>
                {/* <p className='text-white font-medium text-lg mb-2'>My Assets</p> */}
                <AssetsTable assets={assets} />
            </div>
        </div>
    )
}

export default AssetDetails