import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    BarChart,
} from "recharts";

import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { formatShortDateTime } from "../services/common.utils";
import CommonDropdown from "./Common/CommonDropdown";

const HangingHistogram = ({ height = 450, radius = '30px', bg = '#37435e', width = null, setSelectedChartDropdown, selectedType, data = [] }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChange = (value) => {
        console.log(value)
        // let value = event.target.value;
        setSelectedChartDropdown(value)
    };


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="rounded-xl p-4 shadow-lg" style={{
                    background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)'
                }}>
                    <p className="text-sm font-medium">{`${label ? moment(label).format('Do, MMM hh:mm a') : ''}`}</p>
                    {payload.map((entry, index) => (
                        <p
                            key={index}
                            style={{ color: entry.color }}
                            className="text-sm capitalize"
                        >
                            {
                                entry.value !== 0
                                    ?
                                    `${entry.name}: ${entry.value}`
                                    : ''
                            }
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    // Calculate the maximum absolute value of profit or loss
    const maxProfitLoss = Math.ceil(Math.max(
        ...data.map((entry) => Math.abs(entry.profit || 0)),
        ...data.map((entry) => Math.abs(entry.loss || 0))
    ));

    // Ensure tickInterval is at least 1 to avoid infinite loops
    const tickInterval = Math.max(1, Math.ceil(maxProfitLoss / 5)); // Use a safe minimum of 1
    const ticks = [0]; // Start with 0

    // Add positive ticks up to maxProfitLoss
    for (let i = tickInterval; i <= maxProfitLoss; i += tickInterval) {
        ticks.push(i);
    }

    // Add negative ticks down to -maxProfitLoss
    for (let i = -tickInterval; i >= 0; i -= tickInterval) {
        ticks.unshift(i);
    }

    const processedData = data?.map(entry => ({
        ...entry,
        profitRadius: entry.loss === 0 ? [15, 15, 15, 15] : [15, 15, 0, 0],
        lossRadius: entry.profit === 0 ? [15, 15, 15, 15] : [0, 0, 15, 15]
    })).sort((a, b) => {
        // Convert date strings to Date objects
        const dateA = new Date(a.name);
        const dateB = new Date(b.name);

        return dateA - dateB;  // Sort in ascending order
    });
    console.log(data, 'process')
    // const filteredData = processedData?.filter((entry) => {
    //     const entryDate = new Date(entry.name);
    //     const currentDate = new Date();

    //     if (selectedType === 'today') {
    //         return entryDate.toDateString() === currentDate.toDateString(); // Match only today's date
    //     } else if (selectedType === '1 week') {
    //         const startOfWeek = new Date(currentDate);
    //         startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Start of the week (Sunday)
    //         return entryDate >= startOfWeek && entryDate <= currentDate;
    //     } else if (selectedType === '1 month') {
    //         return entryDate.getMonth() === currentDate.getMonth(); // Only current month
    //     } else if (selectedType === 'recent') {
    //         return entryDate >= currentDate.setDate(currentDate.getDate() - 7); // Last 7 days
    //     }
    //     return true; // Default case, show all data
    // });

    const formatXAxisLabel = (date, selectedType) => {
        // const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = new Date(date);
        const currentDate = new Date(); // Today's date

        if (selectedType === 'today') {
            // Show hour based on available data (1-24 range)
            return formatShortDateTime(formattedDate) //formattedDate.getHours() + 1;  // Adjust hour extraction based on your data format
        } else if (selectedType === '1 week') {
            // Show day of the week (e.g., Mon, Tue, Wed...)
            // Get the start of the current week (e.g., Sunday or Monday depending on your locale)
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());  // Adjust for Sunday start
            const diff = Math.floor((formattedDate - startOfWeek) / (1000 * 60 * 60 * 24)); // Get days difference from start of week

            if (diff >= 0 && diff <= 6) {
                return formattedDate.toLocaleDateString('en-US', { weekday: 'short' }); // Show short weekday name
            }
            return '';
        } else if (selectedType === '1 month') {
            // Show days from the 1st to current day of the month
            if (formattedDate.getMonth() === currentDate.getMonth()) {
                return formattedDate.getDate(); // Return day of the month (1-31)
            }
            return ''; // Only show current month's dates
        } else if (selectedType === 'recent') {
            // Show full date-time format
            // return formattedDate.toLocaleDateString('en-US', options);
            return formatShortDateTime(formattedDate) //formattedDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        }
        return '';
    };


    const dropdownOptions = [
        { value: 'recent', label: 'Last Transaction' },
        { value: 'today', label: 'Today' },
        { value: '1 week', label: 'Last 7 Days' },
        { value: '1 month', label: 'Last 30 Days' },
        // { value: 'all', label: 'All Time' },
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: bg,
            padding: '16px',
            borderRadius: radius,
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: 20
                }}
            >
                <div className="label" style={{
                    fontWeight: 600
                }}>Daily Profit / Loss</div>
                <div className="text-xs font-bold md:text-end text-end">
                    {/* <select
                        className="bg-[#212C45] text-white p-2 px-4 rounded-xl border-px border-[#212C45] outline-none w-fit"
                        value={selectedType}
                        onChange={handleChange}
                    >
                        <option value="recent">Recent Transactions</option>
                        <option value="today">Today</option>
                        <option value="1 week">This Week</option>
                        <option value="1 month">This Month</option>
                    </select> */}

                    <CommonDropdown
                        options={dropdownOptions}
                        selectedValue={selectedType}
                        onChange={handleChange}
                        minWidth={150}
                        classes='bg-[#212C45] border border-[#212C45]'
                    />
                </div>
            </div>

            <BarChart
                width={width ? width : (selectedType === 'today') ? 1000 : 600}
                height={height}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: isMobile ? 0 : 20,
                    bottom: 5,
                }}
            >
                <defs>
                    <linearGradient
                        id="colorProfit"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                    >
                        <stop offset="20%" stopColor="rgb(119, 112, 255)" />
                        <stop offset="100%" stopColor="rgb(14, 238, 168)" />
                    </linearGradient>
                    <linearGradient
                        id="colorLoss"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                    >
                        <stop offset="0%" stopColor="#D724FC" />
                        <stop offset="100%" stopColor="#7770FF" />
                    </linearGradient>
                </defs>
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#333"
                />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: 'rgba(255, 255, 255, 0.5)', fontSize: 12 }}
                    tickFormatter={(value) => formatXAxisLabel(value, selectedType)}

                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: 'rgba(255, 255, 255, 0.5)', fontSize: 12 }}
                    ticks={ticks}
                    tickFormatter={(value) => `$${value}`}
                    domain={[0, maxProfitLoss]}
                />
                <Tooltip content={<CustomTooltip />} cursor={false} />

                <Bar
                    dataKey="loss"
                    stackId="a"
                    fill="url(#colorLoss)"
                    x1="0" y1="1" x2="0" y2="0"
                    radius={[15, 15, 15, 15]}
                    // radius={[0, 0, 15, 15]}
                    // radius={(entry => entry.lossRadius)}
                    barSize={isMobile ? 10 : 30}
                />
                <Bar
                    dataKey="profit"
                    stackId="a"
                    fill="url(#colorProfit)"
                    x1="1" y1="1" x2="1" y2="1"
                    radius={[15, 15, 15, 15]}
                    // radius={[15, 15, 0, 0]}
                    // radius={(entry => entry.profitRadius)}
                    barSize={isMobile ? 10 : 30}
                />
            </BarChart>
        </div>
    )
}

export default HangingHistogram;