import React, { useState } from 'react';
import havokIcon from '../assets/images/havok.svg'
import { useNavigate } from 'react-router-dom';
import {
    Bell,
    Mail,
    HelpCircle,
    X,
    Menu,
} from 'lucide-react';
import { Link } from 'react-router-dom';

const LandingSidebarMobile = ({ handleClick }) => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle sidebar visibility
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='md:hidden'>
            {/* Menu Button */}
            <button onClick={toggleSidebar} className="p-2 text-[#1e1e1e] bg-[#33CCCC] rounded-lg">
                <Menu />
            </button>

            {/* Sidebar */}
            <div
                className={`fixed z-20 top-0 right-0 w-full h-full bg-[#1E2946] transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
            >
                <div>
                    {/* Close Button */}
                    <button onClick={toggleSidebar} className="p-2 absolute top-4 right-4 text-white">
                        <X size={24} />
                    </button>
                </div>
                {/* Sidebar Content */}
                <div className='h-[100%] flex flex-col justify-between'>
                    <div className="text-white py-[70px]">
                        <div style={{background: 'linear-gradient(90deg, #07142F 0%, #7E6CFF 50%, #07142F 100%)', width:'100%', height:1}}></div>
                        
                        <div className='px-6 flex flex-col justify-start py-6 gap-6'>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('home', "section1"); toggleSidebar();}}>Home</span></div>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('aboutus', "section2"); toggleSidebar();}}>About Us</span></div>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('services', "section3"); toggleSidebar();}}>Services</span></div>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('solutions', "section41"); toggleSidebar();}}>Solutions</span></div>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('blog', "section5"); toggleSidebar();}}>Blog</span></div>
                            <div><span className='font-medium text-lx text-white uppercase' onClick={() => {handleClick('contact', "section7"); toggleSidebar();}}>Contact</span></div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-end gap-6 px-6 py-12'>
                        {/* <div>
                            <button 
                            onClick={() => {navigate('/login')}}
                            className='bg-[#002F90] text-white font-medium text-lx px-4 py-2 rounded-full'>Log in</button>
                        </div> */}
                        <div>
                        <button 
                        onClick={() => {navigate('/login')}}
                        className='bg-[#3CC8C8] text-[#07142F] font-medium text-lx px-4 py-2 rounded-full'>Log In</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50"
                    onClick={toggleSidebar}
                ></div>
            )}
        </div>
    );
};

export default LandingSidebarMobile;
