import { useState } from 'react';

const TypeDropdown = ({ handleDropdownChange, selectedOption, options }) => {
    const [isOpen, setIsOpen] = useState(false);

    // const options = [
    //     { id: 'profit', label: 'Profit' },
    //     { id: 'loss', label: 'Loss' },
    // ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (optionId) => {
        // setSelectedOption(optionId);
        handleDropdownChange(optionId)
        setIsOpen(false); // Close the dropdown after selection
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="inline-flex justify-center w-full md:w-fit px-4 py-2 text-md font-normal text-white bg-[#37445E] rounded-full shadow-sm"
            >
                {(selectedOption ? options?.find((opt) => opt?.id?.toLowerCase() === selectedOption?.toLowerCase())?.label : 'Select') || 'Select'}
                <svg
                    className="w-5 h-5 ml-2 -mr-1 mt-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            {isOpen && (
                <div className="absolute right-0 z-10 w-32 mt-2 origin-top-right bg-[#38455F] rounded-md shadow-lg ring-1 ring-[#28274D] focus:outline-none">
                    <div className="p-2 text-sm">
                        {options.map((option) => (
                            <div
                                key={option.id}
                                onClick={() => handleSelect(option.id)}
                                className={`flex items-center px-2 py-1 cursor-pointer hover:bg-[#4A5568] rounded-md ${selectedOption === option.id ? 'bg-transparent' : ''
                                    }`}
                            >
                                <span className="ml-2 text-white">{option.label}</span>
                            </div>
                        ))}

                    </div>
                </div>
            )}
        </div>
    );
};

export default TypeDropdown;
