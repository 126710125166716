import React, { useEffect, useState } from 'react';
import AddAsset, { defaultAssetData } from '../../Components/Modal/AddAsset';
import placeholderImg from '../../assets/images/trade/biticon.svg'
import AssetsTable from '../Assets/AssetsTable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { transformToAssets } from '../../services/common.utils';
import { Info } from 'lucide-react';

const AssetsBalance = ({ assets, setAssets }) => {
    const [totalBalance, setTotalBalance] = useState(0);
    const [isAddingAsset, setIsAddingAsset] = useState(false);
    const [newAsset, setNewAsset] = useState(defaultAssetData);
    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const userId = pathSegments[pathSegments.length - 1];

    let token = Cookies.get('token');
    let headers = {
        Authorization: `Bearer ${token}`
    }

    const handleAddAsset = () => {
        setIsAddingAsset(true);
    };

    const handleCancelAddAsset = () => {
        setIsAddingAsset(false);
        setNewAsset(defaultAssetData);
    };

    const updateCoinPrice = async (coin) => {
        if (!coin) return; // Early return for invalid input

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND}coins_price?coin=${coin}`,
                { headers }
            );

            // Validate response and update state
            if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
                const coinValue = response.data[0]?.coinValue || 0;
                setNewAsset((prev) => ({ ...prev, price: coinValue }));
            } else {
                console.warn('Unexpected response format:', response.data);
                setNewAsset((prev) => ({ ...prev, price: 0 })); // Default to 0 if response is invalid
            }
        } catch (error) {
            console.error('Error getting coin price:', error.message || error);
        }
    };

    const handleSaveAsset = async (data) => {
        try {
            // Destructure the necessary properties from `data`
            const { name, amount } = data;

            // Prepare the request body
            const requestBody = {
                coinName: name,
                userId: userId,
                amountInvested: amount
            };

            // Make the API call
            const response = await axios.post(process.env.REACT_APP_BACKEND + 'assets', requestBody, {
                headers: headers
            });

            // Handle success response
            if (response.status === 201 || response.status === 200) {
                toast.success('Asset saved successfully!', { theme: 'dark' });
                handleCancelAddAsset();
            }
        } catch (error) {
            // Handle error
            console.error('Error saving asset:', error);
            toast.error('Failed to save asset', { theme: 'dark' });
        }
    };

    const fetchAssets = async () => {
        let path = `${process.env.REACT_APP_BACKEND}user_assets?userId=${userId}`
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        await axios.get(path, {
            headers: headers,
        })
            .then((apiResponse) => {
                let data = apiResponse?.data
                setTotalBalance('$' + Number(data?.assetsBalance).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }));
                setAssets(transformToAssets(data?.assetsDTOS))
            })
            .catch((apiError) => {
                console.log(apiError)
            });
    }


    useEffect(() => {
        if (userId) {
            fetchAssets();
        }
    }, [userId]);

    return (
        <div className="p-6 bg-[#212F4C] rounded-xl">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-bold text-white">Assets Balance</h2>
                <button
                    onClick={handleAddAsset}
                    className="bg-[#2B3C5E] text-[#fff] px-5 py-2 rounded-full md:min-w-[150px]"
                >
                    Add Assets
                </button>
            </div>
            <div className="text-4xl font-bold text-[#33CCCC] mb-8">{totalBalance}</div>
            <p className='text-white font-medium text-lg mb-2'>My Assets</p>

            {/* table */}
            {assets?.length > 0 ? <AssetsTable assets={assets} /> :
                <p className="mt-5 w-full flex gap-2 items-center rounded-lg bg-[#37435E] px-6 py-5 text-base text-info-800 text-white">
                    <Info />
                    <span>No Asset Found</span>
                </p>}

            {isAddingAsset && (
                <AddAsset
                    addAsset={newAsset}
                    setAddAsset={setNewAsset}
                    openAdd={isAddingAsset}
                    onCloseAdd={handleCancelAddAsset}
                    onSave={handleSaveAsset}
                    updateCoinPrice={updateCoinPrice}
                />
            )}
        </div>
    );
};

export default AssetsBalance;