import React from 'react'

const TradeStatUser = ({
    title,
    value,
    change,
    buttonText,
    gradientBg,
    isButtonVisible,
    addedClass,
    onClickAction
}) => {
    return (
        <div
            className={`${addedClass} p-6 rounded-lg text-white flex flex-col md:flex-row justify-center md:justify-between`}
            style={{ minWidth: '200px', minHeight: '120px', background: gradientBg }}
        >
            <div>
                <h3 className="text-lg font-medium">{title}</h3>
                <div className='flex gap-2 items-end'>
                    <p className="text-2xl font-bold mt-2">{value}</p>
                    {change && (
                        <p className={`text-sm mt-1 flex items-center 
                            ${Number(change?.replace('%', '')) >= 0 ? 
                                'text-green-400' : 'text-red-500'}`}>
                            <span className="mr-1">▲</span>
                            <span className={``}>{change}</span>
                        </p>
                    )}
                </div>

            </div>
            {isButtonVisible && (
                <button
                    type='button'
                    onClick={onClickAction}
                    className="border h-fit border-white rounded-full px-4 py-1.5 mt-4 hover:bg-white hover:text-black transition"
                >
                    {buttonText}
                </button>
            )}
        </div>
    );
};


export default TradeStatUser