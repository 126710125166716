import LandingPageTopbar from "../Components/LandingPageTopBar";
import LandingPage from "./LandingPage";

const LandingPageWrapper = ({sections}) => {
    return (
        <div>
            <LandingPageTopbar sections={sections}/>
        </div>
    )
}

export default LandingPageWrapper;