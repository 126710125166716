import { useEffect, useState } from "react";
import ActionButtons from "./ActionButtons";
import ToggleSwitch from "./ToggleSwitch";
import { formatToUSCurrency } from "../../services/common.utils";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const UserTable = ({
    recordsFiltered,
    currentPage,
    setCurrentPage,
    loading,
    handleOpenTradeUpdate,
    showArchieved,
    sortedData,
    onAddFunds,
    onUpdateAction,
    onUpdateBalance,
    setOpenDelete,
    setCurrentIndex,
    setCurrentUser,
    ViewDetails,
    onWithdrawFund,
    onResetAction,
    onAddAssets
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    // const [recordsFiltered, setRecordsFiltered] = useState(0);
    const recordsPerPage = 20;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const totalPages = Math.ceil(recordsFiltered / recordsPerPage);

    // Function to handle page navigation
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // Render pagination buttons
    const renderPagination = () => {
        const buttons = [];

        if (currentPage > 1) {
            buttons.push(
                <button className="font-medium text-[#32CCCB]" key="prev" onClick={() => handlePageChange(currentPage - 1)}>
                    <ChevronLeft color="white" />
                </button>
            );
        }

        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, startPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    className={`font-medium text-white ${currentPage === i ? 'text-[#32CCCB]' : ''}`}
                    key={i}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        if (currentPage < totalPages) {
            buttons.push(
                <button className="font-medium text-[#32CCCB]" key="next" onClick={() => handlePageChange(currentPage + 1)}>
                    <ChevronRight color="white" />
                </button>
            );
        }

        return buttons;
    };

    let token = Cookies.get('token');



    return (
        <div className={`userTableContainer ${showArchieved ? "archived" : ""}`}>
            {isMobile ? (
                // Mobile view
                sortedData?.map((item, index) => (
                    <div key={index} className="bg-[#1E2946] rounded-lg p-4 mb-4 text-white">
                        <div className="flex justify-between items-center mb-2">
                            <div className="text-lg font-semibold">{item.userName ? item.userName : 'N/A'}</div>
                            <div className="cursor-pointer">
                                <ActionButtons
                                    item={item}
                                    index={index}
                                    onUpdateAction={onUpdateAction}
                                    setOpenDelete={setOpenDelete}
                                    setCurrentIndex={setCurrentIndex}
                                    setCurrentUser={setCurrentUser}
                                    ViewDetails={ViewDetails}
                                    onAddFunds={onAddFunds}
                                    onWithdrawFund={onWithdrawFund}
                                    onUpdateBalance={onUpdateBalance}
                                    onResetAction={onResetAction}
                                    showArchieved={showArchieved}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                            <span>Enable Trading</span>
                            {/* <Switch
                            onChange={() => handleOpenTradeUpdate(item, index)}
                            checked={item.enableTrade}
                        /> */}
                            <div className="flex items-center justify-center">
                                <ToggleSwitch isOn={item.enableTrade} handleOpenTradeUpdate={handleOpenTradeUpdate} item={item} index={index} />
                            </div>
                        </div>
                        <div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
                            <span className="text-[#5AB1A4]">Balance</span>
                            <span>${formatToUSCurrency(item.balance)}</span>
                        </div>
                        <div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
                            <span className="text-[#5AB1A4]">Profit / Loss</span>
                            <span className={item.profitLossType === "Loss" ? 'text-[#FD3549]' : 'text-[#00B087]'}>
                                {item?.profitLossAmount}
                            </span>
                        </div>
                        <div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
                            <span className="text-[#5AB1A4]">Cycle</span>
                            <span>{item.subscriptionPeriod ? item.subscriptionPeriod : 0} days</span>
                        </div>
                        <div className="flex justify-between py-2">
                            <span className="text-[#5AB1A4]">Open Trades</span>
                            <span>{item.openTrades ? item.openTrades : 0}</span>
                        </div>
                        <div className="mt-3">

                        </div>
                    </div>
                ))
            )
                : <div className='mobileTable userDataTable'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='userDataTableHeader text-start'>Name</th>
                                <th className='userDataTableHeader'>Balance</th>
                                <th className='userDataTableHeader'>Profit/ Loss</th>
                                <th className='userDataTableHeader'>Cycle</th>
                                <th className='userDataTableHeader'>
                                    <div className='text-center'>
                                        Open Trades
                                    </div>
                                </th>
                                <th className='userDataTableHeader text-center'>
                                    <div className='text-center'>
                                        Enable Trading
                                    </div>
                                </th>
                                <th className='userDataTableHeader text-end'>
                                    <div className='text-center'>
                                        Action
                                    </div>
                                </th>
                                {/* <th className='userDataTableHeader'>Remove</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <div className="skeleton">
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div><div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                </div>
                            ) : (
                                sortedData.map((item, index) => (
                                    <tr key={index} className='text-white'> {/* Make sure to add a key here */}
                                        <td onClick={() => { ViewDetails(item) }}
                                            data-label="Name" className="uppercase md:text-white cursor-pointer text-start text-lg">{item.userName ? item.userName : 'N/A'}
                                        </td>
                                        <td data-label="Balance" className="text-white text-lg">{formatToUSCurrency(item.balance)}</td>
                                        <td data-label="Profit/ Loss" className={`text-lg ${item.profitLossType === "Loss" ? 'text-[#FD3549]' : 'text-[#00B087]'}`}>
                                            {item?.profitLossAmount}
                                        </td>
                                        <td data-label="Cycle" className="text-white text-lg">
                                            {item.subscriptionPeriod ? item.subscriptionPeriod : 0} days
                                        </td>
                                        <td data-label="Enable Trading" className="text-white text-lg">
                                            <div className='text-center'>
                                                {item.openTrades ? item.openTrades : 0}
                                            </div>
                                        </td>
                                        <td data-label="Enable Trading">
                                            {/* <div className='text-center'>
                                            <Switch
                                                onChange={() => handleOpenTradeUpdate(item, index)}
                                                checked={item.enableTrade}
                                                className='text-center'
                                            />
                                        </div> */}
                                            <div className="flex items-center justify-center">
                                                <ToggleSwitch isOn={item.enableTrade} handleOpenTradeUpdate={handleOpenTradeUpdate} item={item} index={index} />
                                            </div>

                                        </td>
                                        <td data-label="Action">
                                            <ActionButtons
                                                showArchieved={showArchieved}
                                                item={item}
                                                index={index}
                                                onUpdateAction={onUpdateAction}
                                                setOpenDelete={setOpenDelete}
                                                setCurrentIndex={setCurrentIndex}
                                                setCurrentUser={setCurrentUser}
                                                ViewDetails={ViewDetails}
                                                onUpdateBalance={onUpdateBalance}
                                                onAddFunds={onAddFunds}
                                                onWithdrawFund={onWithdrawFund}
                                                onResetAction={onResetAction}
                                                onAddAssets={onAddAssets}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>}
            <div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                <div>
                    <span className='paginationResultText'>
                        Showing {recordsFiltered > 0 ? (currentPage - 1) * recordsPerPage + 1 : 0} to {currentPage * recordsPerPage > recordsFiltered ? recordsFiltered : currentPage * recordsPerPage} of {recordsFiltered} users
                    </span>
                </div>
                <div className='flexRow' style={{ gap: 16 }}>
                    {
                        renderPagination()
                    }
                </div>
            </div>
        </div>
    );
}

export default UserTable