import { ChevronDown, ChevronUp, Ellipsis, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";

const ActionButtons = ({
    showArchieved,
    item,
    index,
    onUpdateAction,
    setOpenDelete,
    setCurrentIndex,
    setCurrentUser,
    ViewDetails,
    onUpdateBalance,
    onAddFunds,
    onResetAction,
    onWithdrawFund,
    onAddAssets
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative flex px-2 gap-3 items-center md:justify-center" ref={dropdownRef} style={{
            justifyContent: isMobile ? 'space-around' : 'center'
        }}>
            {/* Dropdown Trigger Button */}
            {isMobile ?
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className='flex-1'
                >
                    <Ellipsis />
                </button> :
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center gap-2 px-4 py-2 rounded-full text-white"
                    style={{
                        background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
                    }}
                >
                    Action
                    {isOpen ? <ChevronUp className="h-4 w-4" /> :
                        <ChevronDown className="h-4 w-4" />}
                </button>}

            {/* Dropdown Menu */}
            {isOpen && (
                <div className={`absolute mt-2 w-48 sm:left-2 ${isMobile ? '-ml-[120px] top-5' : 'top-7'} bg-[#2A3B59] rounded-lg shadow-lg py-1 z-10 text-white`}>
                    <div className="flex justify-between items-center px-4 py-2 border-b border-[#28274D]">
                        <span className="text-sm">Action</span>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="text-gray-400 hover:text-white"
                        >
                            <X />
                        </button>
                    </div>
                    {showArchieved ?
                        <div className='w-[100%] flex flex-col items-center justify-center px-2'>
                            {/* View Option */}
                            <button
                                onClick={() => {
                                    ViewDetails(item);
                                    setIsOpen(false);
                                }}
                                className="w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                View
                            </button>

                            {/* Delete Option */}
                            <button
                                onClick={() => {
                                    onResetAction(item.id)
                                }}
                                className="w-full text-green-500 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                Restore
                            </button>
                        </div> :
                        <div className='w-[100%] flex flex-col items-center justify-center px-2'>
                            {/* Update Balance Option */}
                            <button
                                onClick={() => {
                                    onUpdateBalance(item, index); 
                                    setIsOpen(false);
                                }}
                                className="w-full text-white mt-2 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                Update Balance
                            </button>

                            {/* Add Funds Option */}
                            <button
                                onClick={() => {
                                    onAddFunds(item, index); 
                                    setIsOpen(false);
                                }}
                                className="w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                Add Funds
                            </button>
                            <button
                                onClick={() => {
                                    onAddAssets(item, index); 
                                    setIsOpen(false);
                                }}
                                className="w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                Add Assets
                            </button>
                            <button
                                onClick={() => {
                                    onWithdrawFund(item, index); 
                                    setIsOpen(false);
                                }}
                                className="w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                Withdraw Fund
                            </button>
                            {/* View Option */}
                            <button
                                onClick={() => {
                                    ViewDetails(item);
                                    setIsOpen(false);
                                }}
                                className="w-full text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                View
                            </button>

                            {/* Delete Option */}
                            <button
                                onClick={() => {
                                    setOpenDelete(true);
                                    setCurrentIndex(index);
                                    setCurrentUser(item);
                                    setIsOpen(false);
                                }}
                                className="w-full text-red-500 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                Delete
                            </button>
                        </div>}
                </div>
            )}


        </div>
    );
};

export default ActionButtons