import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import Cookies from "js-cookie";
import "../Styles/UserDashBoard.css";
import UserType from "../Helpers/getUserType";
import LineGraph from "../Components/Linechart";
import TokenParser from "../Helpers/tokenParser";

const AdminDashboard = () => {
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState("");
  const [chartSelectedValue, setChartSelectedValue] = useState("");
  const [totalBalance, setTotalBalance] = useState(0);
  const [PNL, setPNL] = useState(0);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(0);
  const [openTrades, setOpenTrades] = useState(0);
  const [PNLType, setPNLType] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [previousBalance, setPreviousBalance] = useState("");
  const [openTrade, setOpenTrade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState([]);
  const [scrollHeight, setScrollHeight] = useState();
  const [userName, setUserName] = useState('');
  let token = Cookies.get("token");
  let details = TokenParser(token);
  useEffect(() => {
    let token = Cookies.get("token");
    if (token) {
      let userType = UserType(token);
      if (userType === "ADMIN") navigate("/admin/home");
      else if (userType !== "USER") navigate("/login");
    }
  }, []);

  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const divHeight = divRef.current.clientHeight;
      setScrollHeight(divHeight);
      console.log("Height of the div:", divHeight);
    }
  }, []);

  const fetchDetails = (timeframe='recent') => {
    setLoading(true);
    let token = Cookies.get("token");
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`https://backend.luxara.ai/api/v1/getUserProfile?timeframe=${timeframe}&id=${details.payload.userId}`, {
        headers: headers,
      })
      .then((apiResponse) => {
        console.log("apiResponse", apiResponse.data);
        setUserName(apiResponse.data?.username);
        setTotalBalance(apiResponse.data?.balance);
        setPreviousBalance(apiResponse.data?.previousBalance);
        setUpdatedAt(apiResponse.data?.lastUpdatedOn);
        setPNLType(apiResponse.data?.profitLossType);
        setSubscriptionPeriod(apiResponse.data?.subscriptionPeriod);
        setOpenTrade(apiResponse.data?.enableTrade);
        setOpenTrades(apiResponse.data?.openTrades);
        setPNL(
          apiResponse.data?.profitLossAmount
        );
      })
      .catch((apiError) => {
        console.log("apiError", apiError);
        console.log("apiError", apiError.messsage);
        if (apiError.response.status === 401) {
          navigate("/login");
        }
      });

    axios
      .get("https://backend.luxara.ai/api/v1/history", {
        headers: headers,
      })
      .then((apiResponse) => {
        console.log("wallet activity", apiResponse.data);
        setWalletData(apiResponse.data?.stats);
        setLoading(false);
      })
      .catch((apiError) => {
        console.log("apiError", apiError);
        console.log("apiError", apiError.messsage);
        if (apiError.response.status === 401) {
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    fetchDetails(event.target.value);
  };

  const handleChartDropdownChange = (event) => {
    setChartSelectedValue(event.target.value);
  };


  const renderWalletTable = () => {
    return (
      <div className="mobileTable walletTable">
        <table className="table">
          <thead>
            <tr>
              <th className="walletheader">
                <span style={{ color: '#2BC155' }}>P</span>
                <span style={{ color: 'white' }}>/</span>
                <span style={{ color: '#FF3E3E' }}>L</span>
              </th>
              <th className="walletheader">Date & Time</th>
              <th className="walletheader">Value</th>
              <th className="walletheader">Total amount</th>
            </tr>
          </thead>
          <tbody className="walletbody" style={{ display: 'block', overflowY: 'auto', height: scrollHeight - 100 }}>
            {loading ? (
              <div className="skeleton">
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div><div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div><div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
              </div>
            ) : (
              walletData.map((item, index) => (
                <tr key={index} className="walletRow" style={{ fontSize: '0.8rem' }}>
                  <td data-label="P/L" className="flexRow alignCenter g12">
                    <div>
                      {
                        item.profitLoss?.length > 0 && item.profitLoss[0] === "-" ?
                          <img
                            className="dashboardIcon"
                            style={{ width: 24, height: 24, borderRadius: 8 }}
                            src={require("../assets/lossIcon.svg").default}
                            alt="Loss Icon"
                          />
                          :
                          <img
                            className="dashboardIcon"
                            style={{ width: 24, height: 24, borderRadius: 8 }}
                            src={require("../assets/profitIcon.svg").default}
                            alt="Profit Icon"
                          />
                      }

                    </div>
                    <div>
                      <span
                        style={{ color: item.profitLoss?.length > 0 && item.profitLoss[0] === "-" ? '#FF3E3E' : '#2BC155' }}
                      >{item.profitLoss?.length > 0 && item.profitLoss[0] === "-" ? 'Loss' : 'Profit'}</span>
                    </div>
                  </td>
                  <td data-label="Date & Time" className="flexRow alignCenter">{item.updatedOn.split('T')[0]}<br></br>{item.updatedOn.split('T')[1]}</td>
                  {/* <td className="flexRow alignCenter">{item.updatedOn}</td> */}
                  <td data-label="Value" className="flexRow alignCenter">{`${item.profitLoss ? item.profitLoss : 0}`}</td>
                  <td data-label="Total amount" className="flexRow alignCenter" style={{ color: '#FFEF60' }}>{item.balanceAmount}</td>
                </tr>
              )))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="dashboard">
      <div className="flexCol" style={{ gap: 24 }}>
        <div className="dashboarddetails">
          <div className="flexRow" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <span className="walletname">
              {userName !== '' ? userName + "\'s" : "My"} DASHBOARD ({openTrade ? "Trading is enabled" : "Trading is disabled"}) {loading}
            </span>
          </div>
          <div className="balanceCards">
            <div className="flexCol">
              <div className="flexCol balanceCard">
                <div className="flexRow g12">
                  {/* <div>
                    <img
                      className="dashboardIcon"
                      style={{ width: 24, height: 24 }}
                      src={require("../assets/totalBalance.svg").default}
                    />
                  </div> */}
                  <div className="flexCol" style={{ alignItems: "flex-start" }}>
                    <div>
                      <span className="dashboardFieldName">Total Balance</span>
                    </div>
                    <div>
                      <span className="dashboardFieldValue">
                        {totalBalance}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="flexRow"
                  style={{
                    justifyContent: "flex-start",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#E31892",
                  }}
                >
                  <span className="dashboardFieldName">Previous balance :
                    <span className="balancePrice">&nbsp; {previousBalance}</span>
                  </span>
                </div>
              </div>
              <div
                className="flexRow"
                style={{
                  justifyContent: "flex-start",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#FFF",
                  paddingLeft: 12,
                  paddingTop: 12,
                }}
              >
                <span>Updated at : <span style={{ color: '#72FF91' }}>{updatedAt}</span></span>
              </div>
            </div>

            <div className="balanceCards">
              <div>
                <div className="flexCol balanceCard">
                  <div
                    className="flexCol"
                    style={{ alignItems: "flex-end", marginBottom: 10, width: '100%', paddingTop: -12 }}
                  >
                    {/* <span className="dashboardFilterName">Date Range</span> */}
                    <select
                      value={selectedValue}
                      className="dashboardDropdown"
                      onChange={handleDropdownChange}
                    >
                      {/* <option value="">Select</option> */}
                      <option value="Last Transaction" selected>Last Transcation</option>
                      <option value="today">Today</option>
                      <option value="1 week">1 week</option>
                      <option value="1 month">1 month</option>
                    </select>
                  </div>
                  <div className="flexRow g12">
                    {/* <div>
                      <img
                        className="dashboardIcon"
                        style={{ width: 24, height: 24 }}
                        src={require("../assets/totalBalance.svg").default}
                      />
                    </div> */}
                    <div
                      className="flexCol"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="flexRow" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ marginRight: '5px' }}>
                          <span className="dashboardFieldName">Profit/ Loss</span>
                        </div>
                        <div className="flexRow" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                          <div style={{ position: 'absolute', left: '26px' }}>
                            <img
                              className="dashboardIcon"
                              style={{ width: 12, height: 12 }}
                              src={require("../assets/lossIcon.svg").default}
                              alt="Loss Icon"
                            />
                          </div>
                          <div style={{ position: 'absolute', left: 0 }}>
                            <img
                              className="dashboardIcon"
                              style={{ width: 12, height: 12 }}
                              src={require("../assets/profitIcon.svg").default}
                              alt="Profit Icon"
                            />
                          </div>
                        </div>

                      </div>
                      <div>
                        <span className="dashboardFieldValue">
                          {PNLType === "Profit" ? (
                            <span className="profit">
                              {/* <img
                                style={{ width: 24, height: 24 }}
                                src={
                                  require("../assets/profitamount.svg").default
                                }
                              /> */}
                              {PNL}
                            </span>
                          ) : PNLType === "Loss" ? (
                            <span className="loss">
                              {/* <img
                                style={{ width: 24, height: 24 }}
                                src={
                                  require("../assets/lossamount.svg").default
                                }
                              /> */}
                              {PNL}
                            </span>
                          ) : (
                            <span>{PNL ? PNL : 0}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="flexRow"
                    style={{
                      justifyContent: "flex-start",
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#E31892",
                    }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      View statement
                    </span>
                  </div> */}
                </div>
                <div
                  className="flexRow"
                  style={{
                    justifyContent: "flex-start",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#FFF",
                    paddingLeft: 12,
                    paddingTop: 12,
                  }}
                >
                  <span>Updated at : <span style={{ color: '#72FF91' }}>{updatedAt}</span></span>
                </div>
              </div>
            </div>
            <div className="flexCol">
              <div className="flexCol balanceCard">
                <div
                  className="flexRow"
                  style={{
                    justifyContent: "flex-start",
                    // fontSize: 10,
                    // fontWeight: 500,
                    // color: "#E31892",
                  }}
                >
                  <div className="flexCol subscriptionPeriodCard" style={{
                    alignItems: 'center'
                  }}>
                    <div className="flexRow">
                      <span className="dashboardFieldName" style={{ color: "white" }}>{subscriptionPeriod === 0 ? "Your cycle has ended" : "Your cycle Ends in " + subscriptionPeriod + " Days"}</span>
                    </div>
                    <div className="flexRow">
                      {/* <span><button className="upgradeButton" style={{color:"white"}}>
                      <div className="dashboardFieldName" style={{color:"blue", fontWeight: 600,}}>Upgrade</div>
                        </button></span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flexCol">
              <div className="flexCol balanceCard">
                <div
                  className="flexRow"
                  style={{
                    justifyContent: "flex-start",
                    // fontSize: 10,
                    // fontWeight: 500,
                    // color: "#E31892",
                  }}
                >
                  <div className="flexCol subscriptionPeriodCard" style={{
                    alignItems: 'center'
                  }}>
                    <div className="flexRow">
                      <span className="dashboardFieldName" style={{ color: "white" }}>Open Trades {openTrades}</span>
                    </div>
                    <div className="flexRow">
                      {/* <span><button className="upgradeButton" style={{color:"white"}}>
                      <div className="dashboardFieldName" style={{color:"blue", fontWeight: 600,}}>Upgrade</div>
                        </button></span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '44px 0px' }} />
          <div className="featureCards">
            <div className="featureCard">
              <div
                className="featureName flexRow"
                style={{ justifyContent: 'flex-start', marginBottom: 12 }}
              >
                <span>Wallet Activity</span>
              </div>
              <div>
                {
                  renderWalletTable()
                }
              </div>
            </div>
            <div id="somediv" ref={divRef} className="featureCard">
              <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <div
                  className="flexRow featureName"
                >
                  <span>Overview Balance</span>
                </div>
                <div
                  className="flexCol"
                >
                  {/* <span className="dashboardFilterName">Date Range</span> */}
                  <select
                    value={chartSelectedValue}
                    className="dashboardDropdown"
                    onChange={handleChartDropdownChange}
                  >
                    <option value="recent" selected>Recent Transcations</option>
                    <option value="today">Today</option>
                    <option value="this week">This week</option>
                    <option value="this month">This month</option>
                  </select>
                </div>
              </div>
              <div >
                <LineGraph dropdownSelection={chartSelectedValue} />
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default AdminDashboard;
