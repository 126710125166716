import React from 'react';

const ToggleSwitch = ({ isOn, handleOpenTradeUpdate, item, index }) => {
  return (
    <div
      onClick={() => handleOpenTradeUpdate(item, index)}
      className={`w-12 h-5 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
        isOn ? 'border-0 border-green-500 bg-[#2C2C3F]' : 'border-0 border-[#FC3549] bg-[#2C2C3F]'
      }`}
    >
      <div
        className={`w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
          isOn ? 'translate-x-7 bg-green-400' : 'translate-x-0 bg-[#FC3549]'
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
