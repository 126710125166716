import React, { useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Area,
    CartesianGrid,
} from 'recharts';
import moment from 'moment';
import { formatToUSCurrency } from '../../services/common.utils';

// Preprocess the data
const processData = (walletData) => {
    return walletData
        ?.map((item) => ({
            month: moment(item?.updatedOn, "MM-DD-YYYYTHH:mm:ss A").format("DD MMM"),
            value: parseFloat(item?.balanceAmount),
            profit: item.profit
        }))
        ?.sort((a, b) =>
            moment(a.updatedOn, "MM-DD-YYYYTHH:mm:ss A") -
            moment(b.updatedOn, "MM-DD-YYYYTHH:mm:ss A")
        );
};

// Custom Tooltip Component
// const CustomTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//         return (
//             <div
//                 className="rounded-xl p-4 shadow-lg"
//                 style={{
//                     background: "linear-gradient(90deg, #D724FC 12%, #7770FF 100%)",
//                 }}
//             >
//                 <div className="flex flex-col gap-1">
//                     <div className="flex items-center gap-2">
//                         <span className="text-white font-bold">Profit</span>
//                         <span className="text-green-400 font-semibold">
//                             ↑ 12%
//                         </span>
//                     </div>
//                     <div className="text-white font-medium">
//                         Earnings: ${payload[0].value}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
//     return null;
// };

const ProfitChart = ({ data, height = "h-[500px]" }) => {
    const transformedData = processData(data);
    const yAxisMin = Math.min(...transformedData.map((item) => item.value)) * 0.9;

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const currentValue = payload[0].value;
            const profitPercentage = payload[0]?.payload?.profit

            return (
                <div
                    className="rounded-xl p-4 shadow-lg"
                    style={{
                        background: "linear-gradient(90deg, #D724FC 12%, #7770FF 100%)",
                    }}
                >
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                            <span className="text-white font-bold">Profit</span>
                            <span className={`font-semibold ${profitPercentage >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                {profitPercentage >= 0 ? "↑" : "↓"} {Math.abs(profitPercentage).toFixed(2)}%
                            </span>
                        </div>
                        <div className="text-white font-medium">
                            Earnings: {formatToUSCurrency(currentValue)}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };


    return (
        <div className={`${height} flex items-center`}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={transformedData}>
                    {/* Gradient for overlay */}
                    <defs>
                        <linearGradient id="overlayGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#5eead4" stopOpacity={0.5} />
                            <stop offset="100%" stopColor="#5eead4" stopOpacity={0} />
                        </linearGradient>
                    </defs>

                    {/* X-Axis */}
                    <XAxis
                        dataKey="month"
                        axisLine={true}
                        tickLine={true}
                        tick={{ fill: "#94a3b8" }}
                    />
                    {/* Y-Axis */}
                    <YAxis
                        axisLine={true}
                        tickLine={true}
                        tick={{ fill: "#94a3b8" }}
                        domain={[yAxisMin, "dataMax + 5000"]}
                        tickFormatter={(value) => value >= 1000 ? `${(value / 1000)?.toFixed(2)}K ` : value?.toFixed(2)}
                    />

                    {/* Tooltip */}
                    <Tooltip content={<CustomTooltip />} cursor={false} />

                    {/* Gradient Overlay (Under the Line) */}
                    <Area
                        type="linear"
                        dataKey="value"
                        fill="url(#overlayGradient)"
                        strokeWidth={0}
                        opacity={0.8}
                    />

                    {/* Line (Sharp Line) */}
                    <Line
                        type="linear"
                        dataKey="value"
                        stroke="#5eead4"
                        strokeWidth={3}
                        dot={{ r: 5, fill: "#5eead4", strokeWidth: 2 }}
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProfitChart;
