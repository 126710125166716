import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import btcIcon from '../../assets/images/btc.svg'
import NewsFeed from './NewsFeed';
// news feed images
import img1 from '../../assets/images/market/1.png'
import img2 from '../../assets/images/market/2.png'
import img3 from '../../assets/images/market/3.png'
import { getFirstName, getUserName } from '../../services/common.utils';
import LeftBarMarketData from './LeftBarMarketData';
import ChartPanel from './ChartPanel';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLoader from '../../Components/Common/PageLoader';
import moment from 'moment-timezone';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);

export const articles = [
    {
        id: 1,
        title: "Bitcoin Maintains Stability While Altcoins Rally",
        description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
        timeAgo: "13m ago",
        image: img1,
        source: "CRYPTO NEWS"
    },
    {
        id: 2,
        title: "Bitcoin Maintains Stability While Altcoins Rally",
        description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
        timeAgo: "15m ago",
        image: img2,
        source: "CRYPTO NEWS"
    },
    {
        id: 3,
        title: "Bitcoin Maintains Stability While Altcoins Rally",
        description: "Bitcoin is currently trading at approximately $67,770, displaying stability as altcoins experience a notable rebound. The recent activity in the market has generated interest in cryptocurrencies such as XRP, SOL, and AVAX. Continue Reading:Bitcoin Maintains Stability While Altcoi... ",
        timeAgo: "18m ago",
        image: img3,
        source: "CRYPTO NEWS"
    }
]

const MarketDetails = () => {
    const [name, setName] = useState(getUserName());
    const [loading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);
    let token = Cookies.get('token');
    const [data, setData] = useState({})
    const threshold = 29800;
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [links, setLinks] = useState([
        { value: 1, title: 'Price' },
        { value: 2, title: 'Market Cap' },
    ])
    const { id } = useParams()

    const chartData = {
        labels: Array.from({ length: 20 }, (_, i) => daysOfWeek[i % daysOfWeek.length]), // Repeat days of the week
        datasets: [
            {
                label: 'Price',
                data: [
                    30450, 30300, 30150, 29980, 30050, 29900, 29850, 29720, 29600, 29500,
                    29550, 29700, 29850, 29750, 29800, 29700, 29550, 29600, 29750, 29820,
                    29950, 30000, 30150, 30200, 30350, 30200, 30100, 30050, 29950, 29800,
                    29750, 29600, 29550, 29500, 29650, 29780, 29920, 30030, 30150, 30220,
                    30000, 29880, 29750, 29600, 29580, 29700, 29850, 30000, 30150, 30250,
                    30100, 29980, 29860, 29700, 29550, 29500, 29620, 29750, 29900, 30050,
                    29920, 29780, 29630, 29550, 29700, 29850, 29980, 30100, 30250, 30320,
                    30180, 30000, 29850, 29720, 29590, 29550, 29700, 29820, 29980, 30150,
                    30000, 29850, 29700, 29580, 29650, 29800, 29950, 30050, 30200, 30300,
                    30150, 29980, 29830, 29750, 29600, 29550, 29500, 29650, 29780, 29920, 30030, 30150, 30220,
                    30000, 29880, 29750, 29600, 29580, 29700, 29850, 30000, 30150, 30250,
                    30100, 29980, 29860, 29700, 29550, 29500, 29620, 29750, 29900, 30050,
                    29920, 29780, 29630, 29550, 29700, 29850, 29980, 30100, 30250, 30320,
                    30180, 30000, 29850, 29720, 29590, 29550, 29700, 29820, 29980, 30150,
                    30000, 29850, 29700, 29580, 29650, 29800, 29950, 30050, 30200, 30300, 29650, 29500, 29550, 29680, 29800, 29950, 30000,
                    29880, 29720, 29580, 29450, 29500, 29620, 29780, 29950, 30050, 30180,
                    30030, 29890, 29750, 29620, 29480, 29550, 29680, 29820, 29950, 30020,
                    29870, 29730, 29580, 29440, 29500, 29650, 29790, 29940, 30080, 30200,
                    30150, 30020, 29870, 29740, 29600, 29500, 29640, 29790, 29930, 30060,
                    29890, 29750, 29600, 29500, 29630, 29780, 29920, 30000, 30150, 30300,
                    30150, 30000, 29850, 29700, 29560, 29520, 29680, 29830, 29970, 30100,
                    29950, 29820, 29690, 29560, 29610, 29760, 29900, 30040, 30170, 30300,
                    30100, 29970, 29840, 29710, 29600, 29500, 29630, 29780, 29910, 30020,
                    29850, 29710, 29600, 29520, 29650, 29790, 29910, 30050, 30180, 30310,
                    29950, 30000, 30150, 30200, 30350, 30200, 30100, 30050, 29950, 29800,
                    29750, 29600, 29550, 29500, 29650, 29780, 29920, 30030, 30150, 30220,
                    30000, 29880, 29750, 29600, 29580, 29700, 29850, 30000, 30150, 30250,
                    30100, 29980, 29860, 29700, 29550, 29500, 29620, 29750, 29900, 30050,
                    29920, 29780, 29630, 29550, 29700, 29850, 29980, 30100, 30250, 30320,
                    30180, 30000, 29850, 29720, 29590, 29550, 29700, 29820, 29980, 30150,
                    30000, 29850, 29700, 29580, 29650, 29800, 29950, 30050, 30200, 30300,
                    30150, 29980, 29830, 29650, 29500, 29550, 29680, 29800, 29950, 30000,
                    29880, 29720, 29580, 29450, 29500, 29620, 29780, 29950, 30050, 30180,
                ],
                segment: {
                    borderColor: ctx => (ctx.p0.parsed.y > threshold ? '#00E4FF' : '#FF3B3B'),
                },
                borderWidth: 2,
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: '#8F9BB3',
                    callback: function (value, index, values) {
                        // Use Moment.js to format the date
                        return moment(value).format('DD MMM');
                    },
                },
                grid: { display: false },
            },
            y: {
                ticks: { color: '#8F9BB3' },
                grid: { color: '#2E3A59' },
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem) {
                        return `$${tooltipItem.raw.toLocaleString()}`;
                    }
                }
            },
        },
        chart: {
            type: 'line',
        },

    };

    function formatNumber(num, commaSeparated = false) {
        if (num == null) return 'N/A';
        return commaSeparated
            ? Number(num).toLocaleString(undefined, { maximumFractionDigits: 2 })
            : Number(num).toFixed(2);
    }

    function formatCurrency(num) {
        if (num == null) return 'N/A';
        return `$${Number(num).toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
    }

    function convertCryptoData(input) {
        return {
            ...input,
            price: formatNumber(input.price, true),
            volume: formatNumber(input.volume24h, true),
            marketCap: formatCurrency(input.marketCap),
            fdv: input.totalSupply
                ? formatCurrency(input.totalSupply * input.price)
                : 'N/A',
            totalSupply: input.totalSupply
                ? `${formatNumber(input.totalSupply)} ${input.symbol}`
                : 'N/A',
            allTimeHigh: '--',
            allTimeLow: '--',
            rating: '--',
        };
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            let headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await axios.get(process.env.REACT_APP_BACKEND + "crypto_detail?id=" + id, { headers: headers });
            let converted_data = convertCryptoData(response.data);
            setData(converted_data)
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch data. Please try again.", {
                theme: "dark",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // render
    return (
        <div className="text-white">
            {loading && <PageLoader />}
            <p className='font-semibold text-2xl capitalize text-white mb-5 text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-5">
                {/* Left Panel */}
                <LeftBarMarketData img={btcIcon} cryptoData={data} />

                {/* Right Panel - Chart */}
                <div className="lg:col-span-3 bg-[#202F4C] p-4 rounded-2xl">
                    <ChartPanel btcIcon={data?.logo} cryptoData={data} links={links} data={chartData} options={options} />
                </div>
            </div>
            {/* News Feed */}
            <NewsFeed title={'Bitcoin News'} articles={articles} />
        </div>
    );
};

export default MarketDetails