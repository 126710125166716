import { Ellipsis } from 'lucide-react'
import React from 'react'
import bg from '../../assets/images/circleBg.png'
import { formatNumber, formatToUSCurrency } from '../../services/common.utils'

function ActiveTradeStats({
    value = "0",
    totalTrades = "$0",
    tradePercent = "0%",
}) {
    return (
        <div className='space-y-5 h-full'>
            <div className='bg-[#212F4C] p-5 rounded-2xl flex flex-col justify-center h-full' >
                <div className='flex justify-between'>
                    <span className='text-white text-xl'>Active Trades</span>
                    <button>
                        <Ellipsis color='gray' size={30} />
                    </button>
                </div>

                {/* Chart View */}
                <div className='my-10 relative object-cover bg-no-repeat h-[400px] -ml-9 md:ml-0 md:h-[400px] scale-75 md:scale-100 w-[120%] md:w-full items-center justify-center flex'
                    style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'center' }}>
                    <div className='text-white flex flex-col gap-2 items-center justify-center'>
                        <span className='text-bold text-4xl'>{totalTrades ? formatNumber(totalTrades) : 0}</span>
                        <span className='text-gray-400'>Total</span>
                    </div>
                </div>

                {/* stats */}
                <div className="md:my-9 flex justify-start items-center md:gap-10 text-[#666666]">
                    {/* Total Trades */}
                    <div className="flex space-x-2">
                        <span className="block mt-2 w-2.5 h-2.5 bg-teal-400 rounded-full"></span>
                        <div>
                            <p className="text-sm">Total Trades</p>
                            <p className="text-xl text-white">
                                {totalTrades ? formatToUSCurrency(totalTrades) : 0} 
                                {/* <span className="text-gray-400 text-base">({tradePercent ? tradePercent : '0%'})</span> */}
                            </p>
                        </div>
                    </div>

                    {/* Cycles */}
                    {/* <div className="flex space-x-2">
                        <span className="block mt-2 w-2.5 h-2.5 bg-purple-400 rounded-full"></span>
                        <div>
                            <p className="text-sm">Cycles</p>
                            <p className="text-xl text-white">
                                {cycles} <span className="text-gray-400 text-base">({cyclesPercent})</span>
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ActiveTradeStats